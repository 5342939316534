<div class="container-fluid paddingright">

    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Tracker</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left" (click)="backTo()"><i
                        class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item active" aria-current="page">Manage LR</li>
                   
                    <li class="breadcrumb-item active" aria-current="page">View Tracker</li>
                </ol>
            </nav>

        </div>
        
    </div>
    <div class="contentbox w-90vw mt-3">
        <div class="row">
            <div class="col-md-12">

                <div class="table-responsive ">
                    <input class='search-box' #data placeholder="search"type="text" (keyup)="filterData(data.value)"/>
                    <!-- <table datatable [dtOptions]="dtOptions" class="tablelist" *ngIf='receipts.length > 0'>
                        <thead>
                            <tr>

                                <td>LR NO</td>
                                <td>Vehicle Type</td>
                                <td>Vehicle No</td>
                                <td>From</td>
                                <td>TO</td>
                                <td>Date</td>

                                <td>State Code</td>

                                <td>Fix/Adhoc</td>
                                <td>Start Meter Reading</td>
                                <td>End Meter Reading</td>
                                <td>Trip Start Date</td>
                                <td>Trip End Date</td>
                                <td>No. of Tolls</td>
                                <td>Usage KM</td>
                                <td>Extra Hours</td>


                                
                                

                                <td class="text-center">ACTION</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let receipt of receipts">

                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.LR_no}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                        {{receipt.receipt.vehicleType}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.vehicle_id}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.from}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.to}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.date | date:'yyyy-MM-dd'}}</td>

                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.stateCode}}</td>

                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.fixAdhoc}}</td>

                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.startmeterReading}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.endmeterReading}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.tripStartDate | date:'yyyy-MM-dd'}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.tripEndDate | date:'yyyy-MM-dd'}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.noofTools}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.up_down_km}}</td>
                                <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]">
                                    {{receipt.receipt.extrahour}}</td>
                                

                                
                                
                                <td>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt._id]"
                                            class="btn btn-block"><i matTooltip="View" class="fas fa-print"></i></button>
                                        <button [routerLink]="['/superAdmin/lrmanagement/edit-lr', receipt.receipt._id]"
                                            class="btn btn-block"><i matTooltip="Edit" class="fas fa-pen"></i></button>
                                        <button [routerLink]="['/superAdmin/lrmanagement/detail-lr', receipt.receipt._id]"
                                            class="btn btn-block">
                                            <i matTooltip="Detail" class="fas fa-eye"></i>
                                        </button>
                                    </div>
                                </td>


                            </tr>


                        </tbody>
                    </table> -->
                    <table mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc" (matSortChange)="sortData($event)" class="mat-elevation-z8 row-border hover tablelist" >
                        
                        <ng-container matColumnDef="LR_no">
                            <th mat-header-cell *matHeaderCellDef>System LR No.</th>
                            <td mat-cell *matCellDef="let receipt; let i = index" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.LR_no}} </td>
                          </ng-container>
                    
                        <ng-container matColumnDef="manualLR_no">
                        <th mat-header-cell *matHeaderCellDef>Manual LR No.</th>
                        <td mat-cell *matCellDef="let receipt; let i = index" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.manualLR_no}} </td>
                      </ng-container>

                        <ng-container matColumnDef="vehicleType">
                        <th mat-header-cell *matHeaderCellDef>Vehicle Type</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.vehicleType}} </td>
                        </ng-container>

                    <ng-container matColumnDef="vehicle_id">
                        <th mat-header-cell *matHeaderCellDef>Vehicle No.</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.vehicle_id}} </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="from">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.from}} </td>
                      </ng-container>

                      <ng-container matColumnDef="to">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>TO</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.to}} </td>
                      </ng-container>

                      <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.date | date:'yyyy-MM-dd'}} </td>
                      </ng-container>

                      <ng-container matColumnDef="stateCode">
                        <th mat-header-cell *matHeaderCellDef>State Code</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.stateCode}} </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="fixAdhoc">
                        <th mat-header-cell *matHeaderCellDef>Fix/Adhoc</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.fixAdhoc}} </td>
                      </ng-container>

                      <ng-container matColumnDef="startmeterReading">
                        <th mat-header-cell *matHeaderCellDef>Start Meter Reading</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.startmeterReading}} </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="endmeterReading">
                        <th mat-header-cell *matHeaderCellDef>End Meter Reading</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.endmeterReading}} </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="tripStartDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trip Start Date</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.tripStartDate | date:'yyyy-MM-dd'}} </td>
                      </ng-container>

                      <ng-container matColumnDef="tripEndDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trip End Date</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.tripEndDate | date:'yyyy-MM-dd'}} </td>
                      </ng-container>

                      <ng-container matColumnDef="noofTools">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>No. of Tolls</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.noofTools}} </td>
                      </ng-container>

                      <ng-container matColumnDef="up_down_km">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage KM</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.up_down_km}} </td>
                      </ng-container>

                      <ng-container matColumnDef="extrahour">
                        <th mat-header-cell *matHeaderCellDef>Extra Hours</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt.receipt?._id]"> {{receipt.receipt?.extrahour}} </td>
                      </ng-container>
                    
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    
                </table>
                </div>
                <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>
        </div>
    </div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>