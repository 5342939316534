<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">Edit Office Expense</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left" (click)="backTo()"><i
                        class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">Miscellaneous expenses </li>
                    <li class="breadcrumb-item" (click)="backTo()">Office Expenses</li>

                    <li class="breadcrumb-item active" aria-current="page">Edit Office Expense </li>

                </ol>
            </nav>
        </div>

    </div>

    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="addofficeExpenseForm" (ngSubmit)="updateOfficeExpence()"
                enctype="multipart/form-data">
                <div class="contentbox">
                <div class="row account mb-3">

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Supervisor Name</label>
                            <select id="supervisornamectrl" formControlName="supervisorname" placeholder="Select Status"
                                class="form-control shadow-none">
                                <option disabled value="null">--Select Supervisor Name--</option>
                                <option *ngFor="let s of superVisor" value="{{s._id}}">{{s.full_name}}</option>

                            </select>

                        </div>
                    </div> 
                   
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Category</label>
                            <select id="expenseCategoryctrl" formControlName="expenseCategory"
                                class="form-control shadow-none">
                                <option disabled value="null">--Select Expense Category--</option>
                                <option *ngFor="let cat of subCat" value={{cat._id}}>{{cat.expenseSubCategory}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Company Name</label>
                            <select id="companyNameCtrl" formControlName="companyname" class="form-control shadow-none">
                                <option disabled value="null">--Select Company Name--</option>
                                <option *ngFor="let c of company" value="{{c._id}}">{{c.clientName}}</option>

                            </select>

                        </div>
                    </div> 
                   
                   
                    
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Amount</label>
                            <input type="number" id="expenseAmountctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Amount " formControlName="expenseAmount">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Date</label>
                            <input type="date" id="expenseDatectrl" class="form-control shadow-none"
                                 formControlName="expenseDate">
                        </div>
                    </div>
                    
                    
                    
                    
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Description</label>
                            <input type="text" id="expenseDescriptionctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Description " formControlName="expenseDescription">
                        </div>
                    </div>

                    
                    
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <!-- <div class="form-group accountsinp  mb-3">
                           
                            <label for="">Attachments</label>
                            <input type="file" accept="image/*" (change)="fileChangeEventattachReceipt1($event)"
                                id="LRReceipt1ctrl" class="form-control shadow-none" formControlName="LRReceipt1">

                        </div> -->
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Attachment</label>
                            <!-- <img src="{{apiUrl+'/'+this.particlarVehicle?.drivingLicense}}" width="100" height="100" class="mb-3"> -->
                            <div class="d-flex align-items-center justify-content-between">
                            
                            <input type="file" accept="image/*,.pdf,.doc" id="LRReceipt1ctrl"
                                (change)="fileChangeEventattachReceipt1($event)" class="form-control shadow-none w-auto"
                                formControlName="LRReceipt1">
                            <button class="btn btn-dark btn-sm  px-4 ms-3" type="button" (click)="preview(particlarExpense?.attachReceipt1)" mat-raised-button >Preview</button>
                            <a #showSlip [href]="slipURL" target="_blank"></a>
                        </div>

                        </div>

                    </div>

                    <div class="col-md-12 text-end">
                        <button class="btn btn-outline-dark" type="button" (click)="resetForm()"
                        mat-raised-button>Reset</button>
                        <button class="btn btn-dark ms-2" type="submit" mat-raised-button>Update
                        </button>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
</div>
