<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Date Wise LR Expense Report : {{this.month}} {{year}}</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
        </div>
    </div>
    <div class="contentbox mt-3">
        <div class="row customepadding">
            <div class="col-md-12 table-responsive ">
                <table datatable class="row-border hover tablelist " *ngIf='LrExpenseArray.length > 0'>
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Company Name</td>
                            <td>Supervisor Name</td>
                            <td>LR Number</td>
                            <td>Vehicle Number </td>
                            <td>Toll Tax Paid</td>
                            <td>Labour Paid</td>
                            <td>Other Payment</td>
                            <td>Detention</td>
                            <td>Advance</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user1 of LrExpenseArray">
                            <td>{{user1.date | date:"dd/MM/yyyy"}}</td>
                            <td>{{user1.companyName}}</td>
                            <td>{{user1.SupervisorName}}</td>
                            <td>{{user1.LRNumber}}</td>
                            <td>{{user1.vehicleNumber}}</td>
                            <td>{{user1.tooltaxPaid}}</td>
                            <td>{{user1.labourPaid}}</td>
                            <td>{{user1.otherPayment}}</td>
                            <td>{{user1.detention}}</td>
                            <td>{{user1.advance}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>