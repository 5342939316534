import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-date-wise-expense',
  templateUrl: './date-wise-expense.component.html',
  styleUrls: ['./date-wise-expense.component.css']
})
export class DateWiseExpenseComponent implements OnInit {
  expenseArr:any=[];
  expenseArray:any=[];
  myArr:any=[];
  paramId:any;
  users:any;
  month:any;
  userSessionData:any=JSON.parse(sessionStorage.getItem('userSession'));
  monthArrFull : any=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthArr : any=["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  paramCompany: any;
  paramSupervisor:any;
  year: any;
  constructor(private activatedRoute: ActivatedRoute,private router: Router,private toastr: ToastrService, private superadminService: SuperAdminService) { 
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.paramCompany = param.company;
      this.paramSupervisor = param.supervisor;
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
    })
  }

  ngOnInit(): void {
    this.getExpense()
  }
  getExpense() {
    this.superadminService.getAllExpense()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if(res.status == true){
          this.superadminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                this.myArr= res.data;
                this.myArr = this.myArr.filter((item) => {
                  return item.expenseDate.split('T')[0].split('-')[0] == this.year
                })
                if(this.userSessionData.user_role_id[0]!=1){
                  this.myArr = this.myArr.filter((item)=>{
                    return item.supervisorId==this.userSessionData._id
                  })
                }
                for(let i=0; i<this.myArr.length; i++){
                  if(this.monthArr[this.myArr[i].expenseDate.split('-')[1]-1]==this.paramId && this.myArr[i].companyId==this.paramCompany && this.myArr[i].supervisorId==this.paramSupervisor){
                  this.expenseArr.push({"supervisorId":this.myArr[i].supervisorId,"date":this.myArr[i].expenseDate,"LRNumber":this.myArr[i].LRNumber, "vehicleNumber":this.myArr[i].vehicleNumber, "expenseDescription":this.myArr[i].expenseDescription, "amount":this.myArr[i].totalExpenseAmount, "companyId":this.myArr[i].companyId })
                  }
                }
                this.expenseArr.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.expenseArr[index].companyName = y.clientName
                  })
                })
                this.superadminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
                  if(supervisors.status == true){
                    var supervisorsData = supervisors.data;
                    this.expenseArr.forEach((item,i)=>{
                      this.expenseArr[i].SupervisorName = supervisorsData.filter((item)=> this.expenseArr[i].supervisorId==item._id);
                      this.expenseArr[i].SupervisorName = this.expenseArr[i].SupervisorName[0]?this.expenseArr[i].SupervisorName[0]['full_name']:this.expenseArr[i].SupervisorName[0]
                    })
                    this.expenseArray = this.expenseArr;
                  }else{
                    console.log(res.message);
                  }
                }, error => {
                  console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
        }else{
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }
      }
      )
}
backTo(){
  window.history.back()
}
}

