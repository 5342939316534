import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.css']
})
export class ImagePreviewComponent implements OnInit {

  apiUrl= environment.domain;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any, public sanitizer: DomSanitizer) { }
  imageURL;
  ngOnInit(): void {
    if(typeof(this.data.file)=='string'){
      this.imageURL = this.apiUrl+'/'+this.data.file;
    }
    else{
      const reader = new FileReader();
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.imageURL = value;
      }
      reader.readAsDataURL(this.data.file)
    }
  }

}
