import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ImagePreviewComponent } from '../../LR-management/image-preview/image-preview.component';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-office-expense',
  templateUrl: './office-expense.component.html',
  styleUrls: ['./office-expense.component.css']
})
export class OfficeExpenseComponent implements OnInit {
  filesToUpload: Array<File> = [];
  superVisor;
  company;
  vehicletypes;
  subCat;
  userSessionData;
  @ViewChild('showSlip') showSlip: ElementRef;
  slipURL: any;
  addofficeExpenseForm = new FormGroup({
    supervisorname: new FormControl(null, [Validators.required]),
    expenseCategory: new FormControl(null, [Validators.required]),
    // expenseCategory: new FormControl('', [Validators.required]),
    companyname: new FormControl(null, [Validators.required]),
    //vehicleType: new FormControl('', [Validators.required]),
   // teaExpense: new FormControl('', [Validators.required]),
   // waterExpense: new FormControl('', [Validators.required]),
   // conveyanceExpense: new FormControl('', [Validators.required]),
   // electricityExpense: new FormControl('', [Validators.required]),
    expenseDate:new FormControl('', [Validators.required]),
   // expenseHead: new FormControl('', [Validators.required]),
   // otherCharges: new FormControl('', [Validators.required]),
    expenseAmount: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\d{1,}$/)])),
    expenseDescription: new FormControl('', [Validators.required]),
   // roomRent: new FormControl('', [Validators.required]),
    
    //stationaryExpense: new FormControl('', [Validators.required]),
    //salary: new FormControl('', [Validators.required]),
   // mobileExpense: new FormControl('', [Validators.required]),

    LRReceipt1: new FormControl('', [Validators.required]),
      
    
  })
  constructor(private activatedRoute: ActivatedRoute,private router :Router, private toastr: ToastrService, private superAdminService: SuperAdminService,public dialog: MatDialog, private sanitizer:DomSanitizer) {
   }
  ngOnInit(): void {
    this.getCompanyName();
    this.getSuperVisorName();
    this.getVehicleType();
    this.getSubCat();
    this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
    if(this.userSessionData.user_role_id[0]!=1){
      this.addofficeExpenseForm.get("supervisorname").reset({value:this.userSessionData._id,disabled:true});
    }
  }
  getSuperVisorName(){
    this.superAdminService.getSuperVisorName()
    .subscribe((res: { status: any, message: any, data: any }) => {

      if (res.status == true) {
        var rawdata = res.data;
        this.superVisor = rawdata;
        //this.dtOptions.data = this.users;
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
      }
    }, error => {
    });
  }
  getSubCat(){
    this.superAdminService.getSubCategory()
    .subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        var rawdata = res.data;
        // this.subCat = rawdata.expenseSubCategory;
        this.subCat=rawdata.filter(word => word.expenseCategory === 'Office Expense');
        //this.dtOptions.data = this.users;
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
      }
    }, error => {
    });
  }
  getCompanyName(){
      this.superAdminService.getClient()
        .subscribe((res: { status: any, message: any, data: any }) => {
  
          if (res.status == true) {
            var rawdata = res.data;
            this.company = rawdata;
            //this.dtOptions.data = this.users;
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');
              
            }else {
              this.toastr.error(res.message);
            }
          }
  
        }, error => {
        });
    
  }
  getVehicleType() {
    this.superAdminService.getVehicleType()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.vehicletypes = rawdata;
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });

  }
  fileChangeEventattachReceipt1(fileInput: any) {

    // this.filesToUpload.push(fileInput.target.files[0]);
     this.filesToUpload=[];
     this.filesToUpload.push(fileInput.target.files[0] );
   }
   addofficeExpense() {

    if (this.addofficeExpenseForm.valid) {
      this.removevalidatorcss("supervisornamectrl");
      this.removevalidatorcss("expenseCategoryctrl");
      this.removevalidatorcss("companyNameCtrl");
      //this.removevalidatorcss("vehicleTypectrl");
      //this.removevalidatorcss("teaExpensectrl");
      //this.removevalidatorcss("waterExpensectrl");
      this.removevalidatorcss("expenseDatectrl");
      //this.removevalidatorcss("expenseHeadctrl");
      //this.removevalidatorcss("otherChargesctrl");
      
      this.removevalidatorcss("expenseAmountctrl");
      this.removevalidatorcss("expenseDescriptionctrl");
      
      //this.removevalidatorcss("mobileExpensectrl");
      //this.removevalidatorcss("conveyanceExpensectrl");
      //this.removevalidatorcss("electricityExpensectrl");
      //this.removevalidatorcss("stationaryExpensectrl");
      
      var form = {
       
        "supervisorId": this.addofficeExpenseForm.get('supervisorname').value,
        "expenseSubCategoryId": this.addofficeExpenseForm.get('expenseCategory').value,
        "companyId": this.addofficeExpenseForm.get('companyname').value,
       // "vehicleType": this.addVicheleMaintenanceForm.get('vehicleType').value,
       // "teaExpense": this.addofficeExpenseForm.get('teaExpense').value,
       // "waterExpense": this.addofficeExpenseForm.get('waterExpense').value,
       // "conveyanceExpense": this.addofficeExpenseForm.get('conveyanceExpense').value,
       // "electricityExpense": this.addofficeExpenseForm.get('electricityExpense').value,

        
        "expenseDate": this.addofficeExpenseForm.get('expenseDate').value,
       // "expenseHead": this.addofficeExpenseForm.get('expenseHead').value,
       // "otherCharges": this.addofficeExpenseForm.get('otherCharges').value,
        //"labourAmount": this.addofficeExpenseForm.get('labourAmount').value,
        "expenseAmount": this.addofficeExpenseForm.get('expenseAmount').value,
        "expenseDescription": this.addofficeExpenseForm.get('expenseDescription').value,
        
        
        //"roomRent": this.addofficeExpenseForm.get('roomRent').value,
        //"stationaryExpense": this.addofficeExpenseForm.get('stationaryExpense').value,
        //"salary": this.addofficeExpenseForm.get('salary').value,
        //"mobileExpense": this.addofficeExpenseForm.get('mobileExpense').value,
        
      }
      const formData = new FormData();
       const files: Array<File> = this.filesToUpload;
      
       for (let i = 0; i < this.filesToUpload.length; i++) {
         formData.append("uploads[]", this.filesToUpload[i]);
       }
      formData.append('myFormData', JSON.stringify(form));
     
    this.superAdminService.addOfficeExpense(formData)
    .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
    
        if (res.status == true) {
          //alert(res.message)
          this.toastr.success(res.message);
          this.router.navigate(['/superAdmin/officeExpense/expanceList']);
          this.addofficeExpenseForm.reset();
        }
        else {
          if (res.status === 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
          //this.addNewUser.reset()
        }
      
    }, error => {
    });
    }
    else {

      if (this.addofficeExpenseForm.get('companyname').invalid) {
        this.addvalidatorcss("companyNameCtrl");
      } else {
        this.removevalidatorcss("companyNameCtrl");
      }

      if (this.addofficeExpenseForm.get('supervisorname').invalid) {
        this.addvalidatorcss("supervisornamectrl");
      } else {
        this.removevalidatorcss("supervisornamectrl");
      }
    
      
      
      // if (this.addVicheleMaintenanceForm.get('vehicleType').invalid) {
      //   this.addvalidatorcss("vehicleTypectrl");
      // } else {
      //   this.removevalidatorcss("vehicleTypectrl");
      // }
      if (this.addofficeExpenseForm.get('expenseCategory').invalid) {
        this.addvalidatorcss("expenseCategoryctrl");
      } else {
        this.removevalidatorcss("expenseCategoryctrl");
      }
      
      // if (this.addofficeExpenseForm.get('waterExpense').invalid) {
      //   this.addvalidatorcss("waterExpensectrl");
      // } else {
      //   this.removevalidatorcss("waterExpensectrl");
      // } 
      // if (this.addofficeExpenseForm.get('conveyanceExpense').invalid) {
      //   this.addvalidatorcss("conveyanceExpensectrl");
      // } else {
      //   this.removevalidatorcss("conveyanceExpensectrl");
      // }
      
      if (this.addofficeExpenseForm.get('expenseDescription').invalid) {
        this.addvalidatorcss("expenseDescriptionctrl");
      } else {
        this.removevalidatorcss("expenseDescriptionctrl");
      }
      if (this.addofficeExpenseForm.get('expenseDate').invalid) {
        this.addvalidatorcss("expenseDatectrl");
      } else {
        this.removevalidatorcss("expenseDatectrl");
      }
      if (this.addofficeExpenseForm.get('expenseAmount').invalid) {
        this.addvalidatorcss("expenseAmountctrl");
      } else {
        this.removevalidatorcss("expenseAmountctrl");
      }
      if (this.addofficeExpenseForm.get('LRReceipt1').invalid) {
        this.addvalidatorcss("LRReceipt1ctrl");
      } else {
        this.removevalidatorcss("LRReceipt1ctrl");
      }
    }
  }
  resetForm() {
    this.addofficeExpenseForm.reset();
    if(this.userSessionData.user_role_id[0]!=1){
      this.addofficeExpenseForm.get("supervisorname").reset({value:this.userSessionData.full_name,disabled:true});
    }
    this.filesToUpload=[];
  }
  preview(){
    if(this.filesToUpload.length){
      let file = this.filesToUpload[0];
      if(['image/jpeg','image/png','image/svg','image/svg+xml'].includes(file.type)){
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width:'80%',
          height:'90vh',
          data:{file:file}
      });
      }
      else{
        let url =URL.createObjectURL(file);
        this.slipURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(()=>{
          this.showSlip.nativeElement.click();
        })
      }
    }
    else{
      //condition if the stored image is a pdf,doc
      this.toastr.error('Please upload slip');
    }
  }
  backTo(){
    window.history.back()
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }



}
