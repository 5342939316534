import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LrManagementRoutingModule } from './LR-management-routing.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { UsersListComponent } from './users-list/users-list.component';
// import { AddUserComponent } from './add-user/add-user.component';
// import { EditUserComponent } from './edit-user/edit-user.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LRListComponent } from './lr-list/lr-list.component';
import { AddLrComponent } from './add-lr/add-lr.component';
import { EditLrComponent } from './edit-lr/edit-lr.component';
import { ViewLrComponent } from './view-lr/view-lr.component';
import { sharedModule } from 'src/app/shared/myshared.module';
import {NgxPrintModule} from 'ngx-print';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';
import { DataTablesModule } from "angular-datatables";
import { DetailLrComponent } from './detail-lr/detail-lr.component';
import { ViewTrackerComponent } from './view-tracker/view-tracker.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { NewInvoiceComponent } from './new-invoice/new-invoice.component';
import { GstInvoiceComponent } from './gst-invoice/gst-invoice.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImagePreviewComponent } from './image-preview/image-preview.component'


@NgModule({
  // declarations: [UsersListComponent, AddUserComponent, EditUserComponent],
  declarations: [ LRListComponent, AddLrComponent, EditLrComponent, ViewLrComponent, EditInvoiceComponent, AddInvoiceComponent, DetailLrComponent, ViewTrackerComponent, InvoiceListComponent, NewInvoiceComponent, GstInvoiceComponent, ImagePreviewComponent],
  imports: [
    NgxPrintModule,
    CommonModule,
    DataTablesModule,
    LrManagementRoutingModule,
    NgxChartsModule,
    ReactiveFormsModule,
    sharedModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers:[],
  exports:[
    LrManagementRoutingModule
  ]
})
export class LrManagementModule { }

