export const config = {
    emailregExp: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    passwordregExp: /^(?!.* )(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]).{8,}$/,
    websiteregExp: /^((https?|ftp|smtp):\/\/)?(www.)?[A-Za-z0-9]+\.[A-Za-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
    alphanumeric: /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/,
    numeric: /^[0-9]*$/
};

// .{8,15} means: 8 to 15 characters

// (?!.* ) means: does not contain " "
// (?=.*\d) means: contains at least one digit.
// (?=.*[A-Z]) means: contains at least one capital letter