<div class="menupart">
    <div class="row  mr-0 d-none d-xl-block">
        <div class="col-md-9 col-9">
            <div class="logonav position-relative" [routerLink]="['/superAdmin/mydashboard']"><img
                    src="../../../assets/images/logobig.png" class="logobig" width="220" alt="Radha Swami">
                <div class="logosmall position-absolute top-0 w-100">
                    <img src="../assets/images/svg/logoIcon.svg" width="37" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="col-md-3 col-3 my-auto">
            <div class="closemenum ms-3"><img src="../../../assets/images/icons/close.svg"></div>
        </div> -->
    </div>
    <div class="navigation">
        <ul>
            <li [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div id="dash" [routerLink]="['/superAdmin/mydashboard']"  class="menuarea">
                    <img src="../assets/images/icons/dashboard.png" alt="dashboard"
                        srcset="../assets/images/icons/dashboard.png">
                    <p>Dashboard</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/mydashboard']">
                    <div class="arrowbox"></div>
                    <h6>Dashboard</h6>


                </div>
            </li>

            <li *ngIf="isRole2" [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/usermanagement']" >
                    <img src="../assets/images/icons/user-managment.png" alt="User management"
                        srcset="../assets/images/icons/user-managment.png">
                    <p>
                        User Management
                    </p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/usermanagement']">
                    <div class="arrowbox"></div>
                    <h6>User management</h6>
                </div>

            </li>

            <li [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/lrmanagement']" >
                    <img src="../assets/images/icons/manage-LR-32.png" alt="Manage LR"
                        srcset="../assets/images/icons/manage-LR-32.png">
                    <p>Manage LR</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/lrmanagement']">
                    <div class="arrowbox"></div>
                    <h6>Manage LR</h6>
                </div>
            </li>
            <li *ngIf="isRole2" [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageExpense']" >
                    <img src="../assets/images/icons/expenses.png" alt="manageExpense"
                        srcset="../assets/images/icons/expenses.png">
                    <p>Manage Cash</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageExpense']">
                    <div class="arrowbox"></div>
                    <h6>Manage Cash</h6>
                </div>
            </li>
            <li [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageVehicle']" >
                    <img src="../assets/images/icons/manage-vehicle.png" alt="manageVehicle"
                        srcset="../assets/images/icons/manage-vehicle.png">
                    <p>Manage Vehicle</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageVehicle']">
                    <div class="arrowbox"></div>
                    <h6>Manage Vehicle</h6>
                </div>
            </li>
            <li [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']" >
                <div class="menuarea" [routerLink]="['/superAdmin/manageDriver']" >
                    <img src="../assets/images/icons/manage-driver.png" alt="manageDriver"
                        srcset="../assets/images/icons/manage-driver.png">
                    <p>Manage Driver</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageDriver']"> 
                    <div class="arrowbox"></div>
                    <h6>Manage Driver</h6>
                </div>
            </li>
            <li [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/manageClient']"  >
                    <img src="../assets/images/icons/client.png" alt="manageClient"
                        srcset="../assets/images/icons/client.png">
                    <p>Manage Company</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/manageClient']">
                    <div class="arrowbox"></div>
                    <h6>Manage Company</h6>
                </div>
            </li>
           
            <li [routerLinkActiveOptions]="{exact:
                false}" [routerLinkActive]="['active']">
                <div class="menuarea subMenu" >
                    <img src="../assets/images/icons/manage-log.png" alt="manageExpenseLog"
                        srcset="../assets/images/icons/manage-log.png">
                    <div class="d-flex align-items-center justify-content-between ">
                        <p>Miscellaneous expenses</p>
                        <i class="fa-solid fa-chevron-down"></i>
                    </div>
                </div>
                <div class="customedropdownmenu dashcolll dropMenu">
                    <div class="arrowbox"></div>
                    <h6>Miscellaneous expenses</h6>
                    <ul>
                        <li routerLink="../superAdmin/officeExpense/expanceList"><i
                                class="fa-solid fa-chevron-right"></i> Office Expense</li>
                        <li routerLink="../superAdmin/vehicleMaintenence/vehicleMaintenenceList"><i
                                class="fa-solid fa-chevron-right"></i> Vehicle Maintenance</li>
                        <!-- <li routerLink="../superAdmin/ownVehicleExpence/ownVehicleExpenceList"><i
                                class="fa-solid fa-chevron-right"></i> Our Own Vehicle Expense</li> -->
                    </ul>
                </div>
            </li>
            <li [routerLinkActiveOptions]="{exact:
                true}" [routerLinkActive]="['active']">
                <div class="menuarea" [routerLink]="['/superAdmin/profile']"  >
                    <img src="../assets/images/icons/profile.svg" alt="manageClient"
                        srcset="../assets/images/icons/profile.svg">
                    <p>Profile</p>
                </div>
                <div class="customedropdownmenu dashcolll" [routerLink]="['/superAdmin/profile']">
                    <div class="arrowbox"></div>
                    <h6>Manage Profile</h6>
                </div>
            </li>

            <!-- <div class="logout">
                <div class="logoutinn">
                    <img (click)="logout()" src="../assets/images/icons/logout.svg" alt=""
                    srcset="../assets/images/icons/logout.svg">
                    <div class="customedropdownmenu">
                        <div class="arrowbox"></div>
                        <h6>Logout</h6>
                        
                    </div>
                </div>
            </div> -->


        </ul>
        <ul>
            <li>
                <div id="dash" (click)="logout()" class="menuarea">
                    <img src="../assets/images/icons/exit.png" alt="" srcset="../assets/images/icons/exit.png">
                    <p>Logout</p>
                </div>
                <div class="customedropdownmenu dashcolll" (click)="logout()">
                    <div class="arrowbox"></div>
                    <h6>Logout</h6>
                </div>
            </li>
        </ul>

       

    </div>
    <div class="d-none d-xl-block">
        <div class="menuExtande open ">
            <i class="fa-solid fa-bars"></i>
        </div>
    </div>
    <!-- d-block d-sm-block d-md-none d-lg-none -->
    <!-- <div class="d-block d-sm-none d-md-block"> -->
        <div class="d-block d-sm-block d-md-none d-lg-none">
        <div class="menuExtande close ">
            <i class="fa-solid fa-xmark"></i>
        </div>
    </div>
</div>

