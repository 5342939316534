import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LeftSideNavComponent } from 'src/app/superAdmin/mycommon/left-side-nav/left-side-nav.component';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import {
  Chart,
  PieController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
  SubTitle,
  ArcElement,
  LineController,
  LineElement,
  PointElement,
  BarController,
  BarElement
} from 'chart.js';

Chart.register(
  ArcElement,
  LineController,
  LineElement,
  PieController,
  BarController,
  BarElement,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
  SubTitle
);

@Component({
  selector: 'app-mydashboard',
  templateUrl: './mydashboard.component.html',
  styleUrls: ['./mydashboard.component.css']
})
export class MydashboardComponent implements OnInit {
  dispenseArray = [];
  expenseArray = [];
  lrExpenseArray = [];
  categorybiseExpenseAmountArray = [];
  view: any[] = [900, 400];
  userSessionData:any=JSON.parse(sessionStorage.getItem('userSession'));
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Months';
  showYAxisLabel = true;
  yAxisLabel = 'Amount';
  legendTitle: string = 'Amount Type';
  colorScheme = {
    domain: ['#48c9b0', '#146c43', '#AAAAAA']
  };
  dispenseAmount = [];
  totalDispenseAmount = 0;
  ExpenseAmount = [];
  totalExpenseAmount = 0;
  monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  monthArrayNo = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  catArray = ["Office Expense", "Vehicle Maintenance"];
  monthbisetotaldispenseAmount = 0;
  monthbisetotalExpenseAmount = 0;
  abc: any;
  summary: any = {};
  vehicles: any = {
    totalVehicles: 0,
    documentsExpired: 0
  };
  monthExpenses: any = [];
  monthDispenses: any = [];
  @ViewChild('yearFilter') yearFilter:ElementRef;
  dtOptions: {};
  currentDate: any;
  years: Array<String> = [];
  year: number;
  ExpenseAmountCategoryWise: any;
  lrExpenseAmount: any;
  invoices: any;
  lorry_receipts: any;
  chart: Chart<"bar", any, string>;
  constructor(private router: Router, private superadminService: SuperAdminService, private spinner: NgxSpinnerService, private toastr: ToastrService) {

  }
  ngOnInit() {
    for(let y=2023;y<=2024;y++){
      this.years.push(String(y))
    }
    this.spinner.show();
    this.dtOptions = {
      order: false,
      paging: false
    }
    this.year = new Date().getFullYear();
    this.getDispense();
    this.getExpense();
    this.getSummary();
    this.getMasterDataCategoryGroup();
    this.getLRExpenseMonthWise();
    this.superadminService.getVehicle().subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status === true) {
        this.vehicles.totalVehicles = res.data.length;
        let documentsExpired = 0;
        let currentDate = new Date();
        for (let vehicle of res.data) {
          if (new Date(vehicle['permitDueDate']) < currentDate ||
            new Date(vehicle['permitYearsDueDate']) < currentDate ||
            new Date(vehicle['pollutionExpiryDate']) < currentDate ||
            new Date(vehicle['roadTaxDate']) < currentDate ||
            new Date(vehicle['insuranceDueDate']) < currentDate ||
            new Date(vehicle['RCTaxDate']) < currentDate ||
            new Date(vehicle['fitnessDueDate']) < currentDate) {
            documentsExpired += 1;
          }
        }
        this.vehicles.documentsExpired = documentsExpired;
        const vehicals = new Chart("vehicals", {

          type: 'doughnut',
          data: {
            labels: ['Total Vehicles', 'Vehicles Document Expired'],
            datasets: [
              {
                data: [this.vehicles.totalVehicles, this.vehicles.documentsExpired],
                backgroundColor: ['#d9b02b', '#702bd9'],
                borderWidth: 3,
                borderColor: 'transparent',
                borderRadius: 0,
              }
            ]
          },
          options: {
            cutout: 100,
            layout: {
              padding: 0
            },
            plugins: {
              title: {
                display: false,
                text: 'Value each Currency in EUR'
              },

              legend: {
                display: true,
                position: 'bottom',

              },

            },
            maintainAspectRatio: false,
            responsive: true,


          }
        });
      }
      else {
        alert(res.message);
      }
    });
  }
  ngAfterViewInit(){
    this.yearFilter.nativeElement.value = this.year
  }
  changeYear(){
    this.year = this.yearFilter.nativeElement.value;
  }
  changeYearDispense(){
    let dispenseAmountParticularYear = this.dispenseAmount.filter((item)=>{
      console.log(item._id.year);
      return item._id.year == this.year
    })
    this.dispenseArray=[];
    this.monthDispenses=[];
    this.monthArray.forEach((item)=>{
      this.dispenseArray.push({
        "month":item,
        "amount":0
      })
    });
    if(this.userSessionData.user_role_id[0]!=1){
      dispenseAmountParticularYear = dispenseAmountParticularYear.filter((item)=>{
        return item._id.supervisorId==this.userSessionData._id
      })
    }
    dispenseAmountParticularYear.forEach(item => {
      this.dispenseArray[item._id.month-1].amount += item.amount
    });
    this.dispenseArray.forEach(item=>{
      this.monthDispenses.push(item.amount);
    })
    this.totalDispenseAmount = this.monthDispenses.reduce((acc,curr)=>acc+curr,0);
  }
  changeYearLrExpense(){
    let expenseAmountParticularYear = this.lrExpenseAmount.filter((item)=>{
      console.log(item._id.year);
      return item._id.year == this.year
    })
    this.lrExpenseArray = [];
    if(this.userSessionData.user_role_id[0]!=1){
      expenseAmountParticularYear = expenseAmountParticularYear.filter((item)=>{
        return item._id.supervisorId==this.userSessionData._id
      })
    }
    this.monthArray.forEach((item)=>{
      this.lrExpenseArray.push({
        "month":item,
        "amount":0
      })
    });
    setTimeout(()=>{
      expenseAmountParticularYear.forEach((item)=>{
        this.lrExpenseArray[item._id.month-1].amount += item.amount;
        this.monthExpenses[item._id.month-1] += item.amount;
        this.totalExpenseAmount += item.amount;
      })
      setTimeout(()=>{
        this.chart?.destroy();
        this.chart = new Chart("expanceAmount", {
          type: 'bar',
          data: {
            labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            datasets: [
              {
                label: 'Cash Dispense',
                data: this.monthDispenses,
                borderColor: 'transparent',
                backgroundColor: '#702bd9',
                borderWidth: 3,
                barThickness: 15,
                borderRadius: 15,
                borderSkipped: false,
                barPercentage: 0.5,
              },
              {
                label: 'Expense',
                data: this.monthExpenses,
                borderColor: 'transparent',
                backgroundColor: '#d9b02b',
                borderWidth: 3,
                barThickness: 15,
                borderRadius: 15,
                borderSkipped: false,
                barPercentage: 0.5,
              }
            ]

          },
          options: {
            layout: {
              padding: 0
            },
            plugins: {
              title: {
                display: false,
                text: 'Value each Currency in EUR'
              },

              legend: {
                display: true,
                position: 'bottom',
              },

            },
            maintainAspectRatio: false,
            responsive: true,

            scales: {
              x: {

                grid: {
                  display: false,
                  offset: true,
                  color: '#575E7B',
                },
                ticks: {

                  color: '#575E7B'
                }
              },
              y: {
                grid: {
                  display: false,
                  offset: false,
                  color: '#575E7B',
                },
                ticks: {

                  color: '#575E7B'
                }
              }
            }
          }
        });
        this.spinner.hide();
      },50);
    },50)
  }
  changeYearExpense(){
    let expenseAmountParticularYear = this.ExpenseAmount.filter((item)=>{
      return item._id.year == this.year
    })
    this.expenseArray=[];
    this.monthExpenses=[];
    if(this.userSessionData.user_role_id[0]!=1){
      expenseAmountParticularYear = expenseAmountParticularYear.filter((item)=>{
        return item._id.supervisorId==this.userSessionData._id
      })
    }
    this.monthArray.forEach((item)=>{
      this.expenseArray.push({
        "month":item,
        "amount":0
      })
    });
    expenseAmountParticularYear.forEach(item => {
      this.expenseArray[item._id.month-1].amount += item.amount
    });
    this.expenseArray.forEach(item=>{
      this.monthExpenses.push(item.amount);
    })
    this.totalExpenseAmount = this.monthExpenses.reduce((acc,curr)=>acc+curr,0);
  }
  changeYearExpenseCategoryWise(){
    let expenseAmountParticularYearCategoryWise = this.ExpenseAmountCategoryWise.filter((item)=>{
      return item._id.year == this.year
    })
    this.categorybiseExpenseAmountArray = []
    if(this.userSessionData.user_role_id[0]!=1){
      expenseAmountParticularYearCategoryWise = expenseAmountParticularYearCategoryWise.filter((item)=>{
        return item._id.supervisorId==this.userSessionData._id
      })
    }
    const officeExpenseData = expenseAmountParticularYearCategoryWise.filter((item)=>{
      return item._id.category[0] == 'Office Expense'
    })

    const vehicleMaintenanceData = expenseAmountParticularYearCategoryWise.filter((item)=>{
      return item._id.category[0] == 'Vehicle Maintenance'
    })
    let temp = [];
    officeExpenseData.forEach((item)=>{
      temp.push(item.totalExpenseAmount);
    })
    const officeExpenseTotal = temp.reduce((accumulator,currentvalue)=>accumulator + currentvalue, 0);
    temp = [];
    vehicleMaintenanceData.forEach((item)=>{
      temp.push(item.totalExpenseAmount);
    })
    const vehicleMaintenanceTotal = temp.reduce((accumulator,currentvalue)=>accumulator + currentvalue, 0);
    this.categorybiseExpenseAmountArray.push(
      { "expenseCategory":"Office Expense" , "amount": officeExpenseTotal },
      { "expenseCategory":"Vehicle Maintenance" , "amount": vehicleMaintenanceTotal }
    ); 
  }
  changeSummary(){
    let invoices = this.invoices.filter((item=>{
      return item.created_date.split('T')[0].split('-')[0] == this.year
    }))
    let lorry_receipts = this.lorry_receipts.filter((item=>{
      return item.receipt.date.split('T')[0].split('-')[0] == this.year
    }))
    if(this.userSessionData.user_role_id[0]!=1){
      invoices = invoices.filter(item=>{
        return this.userSessionData.clientList.includes(item.clientId)
      })
      lorry_receipts = lorry_receipts.filter(item=>{
        return this.userSessionData.clientList.includes(item.receipt.clientId)
      })
    }
    let runningLR = 0;
    lorry_receipts.forEach(lr => {
        if (lr.receipt.tripEndDate == null)
            runningLR += 1;
    });
    const totalLR = lorry_receipts.length
    const closedLR = totalLR - runningLR;
    let generatedInvoice = 0;
    invoices.forEach(element => {
        generatedInvoice += element.invoice[0]['LR_no'].length
    })
    const totalInvoice = closedLR;
    const pendingInvoice = closedLR - generatedInvoice;
    this.summary = {
        totalLR: totalLR,
        runningLR: runningLR,
        closedLR: closedLR,
        totalInvoice: totalInvoice,
        generatedInvoice: generatedInvoice,
        pendingInvoice: pendingInvoice

    };
  }
  getSummary(){
    this.superadminService.getInvoice().subscribe((invoicesRes: { status: any, message: any, data: any }) => {
      if (invoicesRes.status === true) {
        this.invoices = invoicesRes.data?invoicesRes.data:[];
        this.superadminService.getlrs().subscribe((lrRes: { status: any, message: any, data: any }) => {
          if (lrRes.status === true) {
            this.lorry_receipts = lrRes.data?lrRes.data:[];
            this.changeSummary();
          }
          else {
            alert(lrRes.message);
          }
        });
      }
      else {
        alert(invoicesRes.message);
      }
    });
  }
  getDispense() {
    this.superadminService.getDispenseMonthWise()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.dispenseAmount = rawdata;
          // console.log(this.year);
          this.changeYearDispense();
          console.log('**', this.dispenseAmount);
          
        }
        else {
          alert(res.message);
        }

      }, error => {
      });
  }

  getLRExpenseMonthWise() {
    this.superadminService.getLRExpenseMonthWise()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.lrExpenseAmount = rawdata;
          this.changeYearLrExpense();
        }
        else {
          alert(res.message);
        }

      }, error => {
      });
  }

  getExpense() {
    this.superadminService.getExpenseMonthWise()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.ExpenseAmount = rawdata;
          this.changeYearExpense();
        }
        else {
          this.spinner.hide();
          alert(res.message)
        }

      }, error => {
        this.spinner.hide();
        this.toastr.error("Something went wrong! Please contact us")
      });
  }

  getMasterDataCategoryGroup(){
    this.superadminService.getMasterDataCategoryGroup()
    .subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        //alert(res.message)
        this.ExpenseAmountCategoryWise = res.data;
        this.changeYearExpenseCategoryWise();
      }
      else {
       if (res.status == 401) {
         this.toastr.error(res.message);
         window.sessionStorage.clear();
         this.router.navigateByUrl('superAuth/login');
         
       }else {
         this.toastr.error(res.message);
       }
      }
    }, error => {
      this.toastr.error('internal server error');
     })
  }
  onSelect(data): void {
  }

  onActivate(data): void {
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  backTo() {
    window.history.back()
  }
}
