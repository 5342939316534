<div class="container-fluid">

    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">Add Vehicle Maintenance   </h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left" (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">Miscellaneous expenses</li>
                    <li class="breadcrumb-item" (click)="backTo()">Vehicle Maintenance</li>
                    <li class="breadcrumb-item active" aria-current="page">Add Vehicle Maintenance</li>

                </ol>
            </nav>
        </div>

    </div>

    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="addVicheleMaintenanceForm" (ngSubmit)="addVicheleMaintenance()"
                enctype="multipart/form-data">
                <div class="contentbox">
                <div class="row account mb-3">

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Supervisor Name</label>
                            <select id="supervisornamectrl" formControlName="supervisorname" 
                                class="form-control shadow-none">
                                <option disabled selected value="null"  >--Select Supervisor Name--</option>
                                <option *ngFor="let s of superVisor" value="{{s._id}}">{{s.full_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Category</label>
                            <select id="expenseCategoryctrl" formControlName="expenseCategory"
                                class="form-control shadow-none">
                                <option disabled value="null" aria-placeholder="--Select Expense Category-">--Select Expense Category--</option>
                                <option *ngFor="let cat of subCat" value={{cat._id}}>{{cat.expenseSubCategory}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Company Name</label>
                            <select id="companynamectrl" formControlName="companyname" class="form-control shadow-none">
                                <option disabled value="null">--Select Company Name--</option>
                                <option *ngFor="let c of company" value="{{c._id}}">{{c.clientName}}</option>

                            </select>
                        </div>
                    </div>
                   
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Vehicle Number</label>
                            <input type="text" id="vehicleNumberctrl" class="form-control shadow-none"
                                placeholder="Enter Vehicle Number" formControlName="vehicleNumber">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Vehicle Type</label>
                            <select id="vehicleTypectrl" formControlName="vehicleType" placeholder="Select Vehicle Type"
                                class="form-control shadow-none">
                                <option disabled value="null">--Select Vehicle Type--</option>
                                <option *ngFor="let c of vehicletypes" value="{{c.vehicletype_name}}">
                                    {{c.vehicletype_name}}</option>

                            </select>

                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Date</label>
                            <input type="date" id="expenseDatectrl" class="form-control shadow-none"
                                placeholder="Enter Rate " formControlName="expenseDate">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Amount</label>
                            <input type="number" id="expenseAmountctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Amount " formControlName="expenseAmount">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Other Charges</label>
                            <input type="text" id="otherChargesctrl" class="form-control shadow-none"
                                placeholder="Enter Other Charges" formControlName="otherCharges">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Mechanic Name</label>
                            <input type="text" id="mechanicNamectrl" class="form-control shadow-none"
                                placeholder="Enter Mechanic Name " formControlName="mechanicName">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Mechanic Number</label>
                            <input type="text" id="mechanicNumberctrl" class="form-control shadow-none"
                                placeholder="Enter Mechanic Number " formControlName="mechanicNumber">
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Head</label>
                            <input type="text" id="expenseHeadctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Head " formControlName="expenseHead">
                        </div>
                    </div> -->
                   
                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Labour Amount</label>
                            <input type="text" id="labourAmountctrl" class="form-control shadow-none"
                                placeholder="Enter Labour Amount " formControlName="labourAmount">
                        </div>
                    </div> -->
                    
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Description</label>
                            <input type="text" id="expenseDescriptionctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Description " formControlName="expenseDescription">
                        </div>
                    </div>


                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Meter Reading</label>
                            <input type="text" id="meterReadingctrl" class="form-control shadow-none"
                                placeholder="Enter Meter Reading " formControlName="meterReading">
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Work Description</label>
                            <input type="text" id="workDescriptionctrl" class="form-control shadow-none"
                                placeholder="Enter Work Description " formControlName="workDescription">
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Parts Name</label>
                            <input type="text" id="partsNamectrl" class="form-control shadow-none"
                                placeholder="Enter Parts Name " formControlName="partsName">
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Part Amount</label>
                            <input type="text" id="partAmountctrl" class="form-control shadow-none"
                                placeholder="Enter Part Amount " formControlName="partAmount">
                        </div>
                    </div> -->
                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Labour Charge</label>
                            <input type="text" id="labourChargectrl" class="form-control shadow-none"
                                placeholder="Enter Labour Charge " formControlName="labourCharge">
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Work Place</label>
                            <input type="text" id="workPlacectrl" class="form-control shadow-none"
                                placeholder="Enter Work Place " formControlName="workPlace">
                        </div>
                    </div> -->
                    
                    

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Attachment</label>
                            <div class="d-flex align-items-center justify-content-between">
                                <input type="file" accept="image/*,.jpg,.pdf" (change)="fileChangeEventattachReceipt1($event)"
                                    id="LRReceipt1ctrl" class="form-control shadow-none" formControlName="LRReceipt1">
                                <button class="btn btn-dark btn-sm  px-4 ms-3" type="button" (click)="preview()" mat-raised-button >Preview</button>
                                <a #showSlip [href]="slipURL" target="_blank"></a>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-12 text-end">
                        <button class="btn btn-outline-dark me-1" type="button" (click)="resetForm()"
                        mat-raised-button>Reset</button>
                        <button class="btn btn-dark ms-1" type="submit" mat-raised-button>Save
                        </button>
                       
                    </div>
                </div>
                </div>
            </form>
        </div>
    </div>
</div>