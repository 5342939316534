import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MydashboardRoutingModule } from './mydashboard-routing.module';
import { MydashboardComponent } from './mydashboard.component';
import { LeftSideNavComponent } from '../mycommon/left-side-nav/left-side-nav.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserModule } from '@angular/platform-browser';
import { ViewComponent } from './view/view.component';
import { MonthlyExpenseComponent } from './monthly-expense/monthly-expense.component';
import { CategoryExpenseComponent } from './category-expense/category-expense.component';
import { DateWiseDispenseComponent } from './date-wise-dispense/date-wise-dispense.component';
import { DateWiseCategoryComponent } from './date-wise-category/date-wise-category.component';
import { DateWiseExpenseComponent } from './date-wise-expense/date-wise-expense.component';
import { DataTablesModule } from "angular-datatables";
import { sharedModule } from 'src/app/shared/myshared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MonthlyLrExpenseComponent } from './monthly-lr-expense/monthly-lr-expense.component';
import { DateWiseLrExpenseComponent } from './date-wise-lr-expense/date-wise-lr-expense.component';
@NgModule({
  declarations: [MydashboardComponent, ViewComponent, MonthlyExpenseComponent, CategoryExpenseComponent, DateWiseDispenseComponent, DateWiseCategoryComponent, DateWiseExpenseComponent, MonthlyLrExpenseComponent, DateWiseLrExpenseComponent],
  imports: [
    CommonModule,
    MydashboardRoutingModule,
    NgxChartsModule,
    DataTablesModule,
    sharedModule,
    NgxSpinnerModule

  ],
  providers: [],
  exports: [
    MydashboardComponent
  ]
})
export class MydashboardModule { }
