import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../environments/environment'
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {
  baseUrl = `${environment.domain}`;
  userSessionData;
  constructor(private toastr: ToastrService, private http: HttpClient, private router: Router) {
  }
  // this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
  getHeader(): HttpHeaders {
    const token = sessionStorage.getItem('token') || "null";
    if (token == "null") {
      // this.toastr.error(res.message);
      this.toastr.error("Session Expired");
      window.sessionStorage.clear();
      this.router.navigateByUrl('superAuth/login');
      //  setTimeout(()=>{
      //   window.sessionStorage.clear();
      //   this.router.navigateByUrl('superAuth/login');
      //  })
    }
    let headers = new HttpHeaders({
      "authorization": `Bearer ${token}`,
    });
    return headers
  }
  deleteDailogBox(): any {
    if (confirm("Are you sure you want delete?") == true) {
      return true;
    } else {
      return false;
    }

  }


  signIn({ form }): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/auth/signIn`;

    return this.http.post<{ status: any, message: any }>(url, { form })
      .pipe(tap({
        next: (val: { status: any, message: any }) => {
        },
        error: error => {
        },
        complete: () => {
        }
      })
      );
  }

  getUsers(): Observable<{ status: any, message: any, data: any }> {
    let option = { headers: this.getHeader() }
    console.log("option", option)
    const url = `${environment.domain}/auth/getusers`;
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getAllUsers(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/auth/getallusers`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  getSingleUsers({ form }): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/auth/getSingleUser`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any, data: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  changePassword({ form }): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/auth/changePassword`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any, data: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  createUser({ form }): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/auth/createuser`;
    let option = { headers: this.getHeader() }
    console.log("option", option)


    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => { },
          error: error => { },
          complete: () => { }
        })
      );
  }

  updateuser({ form }): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/auth/updateuser`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  editSingleUser({ form }): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/auth/editSingleUser`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }


  deleteuser(email): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/auth/deleteuser`;
    let option = { headers: this.getHeader() }
    console.log("email", email)

    return this.http.post<{ status: any, message: any }>(url, { email }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  getlrs(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/lr/getlrs`;
    let option = { headers: this.getHeader() }

    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getlrsbyClientId(clientId): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/lr/getlrsbyClientId`;
    let option = { headers: this.getHeader() }
    console.log("hey", option)
    return this.http.post<{ status: any, message: any, data: any }>(url, { clientId }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  createLR(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/lr/createLR`;
    let option = { headers: this.getHeader() }
    // console.log("hey", option)
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  addInvoice(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/lr/addInvoice`;
    let option = { headers: this.getHeader() }
    console.log("hey", option)
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  updateInvoice(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/lr/updateInvoice`;
    let option = { headers: this.getHeader() }
    console.log("hey", option)
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  deleteInvoice(_id): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/invoice/deleteInvoice`;
    let option = { headers: this.getHeader() }

    return this.http.post<{ status: any, message: any }>(url, { _id }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  updateLR({ form }): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/lr/updateLR`;
    let option = { headers: this.getHeader() }
    console.log("hey", option)
    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  particlarLR(id: any): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/lr/particlarLR/` + id;
    let option = { headers: this.getHeader() }
    console.log("hey", option)
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  getvehicles(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/lr/getvehicles`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getCompanyName(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageExpense/getcompany`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getSuperVisorName(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageExpense/getSuperVisor`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  createDispense({ form }): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageExpense/createDispense`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  getDispense(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageExpense/getDispense`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  deleteDispense(_id): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageExpense/deleteDispense`;

    let option = { headers: this.getHeader() }
    console.log("email", _id)

    return this.http.post<{ status: any, message: any }>(url, { _id }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  getParticularDispense(id: any): Observable<{ status: any, message: any, data: any }> {

    const url = `${environment.domain}/manageExpense/particlarDispense/` + id;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  updateDispense({ form }): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageExpense/updateDispense`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  getVehicleType(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageVehicle/getVehicleType`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getDriverName(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageVehicle/getDriverName`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  addVehicle(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageVehicle/addVehicle`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getVehicle(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageVehicle/getVehicle`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  deleteVehicle(_id): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageVehicle/deleteVehicle`;
    let option = { headers: this.getHeader() }
    console.log("email", _id)

    return this.http.post<{ status: any, message: any }>(url, { _id }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  getparticlarVehicle(id: any): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageVehicle/particlarVehicle/` + id;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  updateVehicle(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageVehicle/updateVehicle`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  addDriver(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageDriver/addDriver`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getDrivers(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageDriver/getDriver`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  deleteDriver(_id): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageDriver/deleteDriver`;
    let option = { headers: this.getHeader() }
    //console.log("email", _id)

    return this.http.post<{ status: any, message: any }>(url, { _id }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getparticlarDriver(id: any): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageDriver/particlarDriver/` + id;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  updateDriver(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageDriver/updateDriver`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  createClient({ form }): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageClient/addClient`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getClient(): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageClient/getClient`;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  deleteClient(_id): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageClient/deleteClient`;
    let option = { headers: this.getHeader() }
    //console.log("email", _id)

    return this.http.post<{ status: any, message: any }>(url, { _id }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getParticularClient(id: any): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/manageClient/particlarClient/` + id;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  updateClient({ form }): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageClient/updateClient`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  addHireVehicleFlat(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageExpenseLog/addHireVehicleFlat`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  addTollFiles(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/lr/addTollFiles`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  addloadingSlips(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/lr/addloadingSlips`;
    // let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  addHireVehicleMonthly(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageExpenseLog/addHireVehicleMonthly`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  addOfficeExpense(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageExpenseLog/addOfficeExpense`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  addVicheleMaintenance(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageExpenseLog/addVicheleMaintenance`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  addOwnVicheleMaintenance(formData): Observable<{ status: any, message: any }> {
    const url = `${environment.domain}/manageExpenseLog/addOwnVicheleMaintenance`;
    let option = { headers: this.getHeader() }
    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  sendEmail(id: any, binary: any) {
    let option = { headers: this.getHeader() }
    return this.http.post(this.baseUrl + '/lr/sendEmail/' + id, { binary }, option);
  }

  getClientList() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/manageClient/getClient', option);
  }


  filterData({ form }): Observable<{ status: any, message: any }> {
    let option = { headers: this.getHeader() }
    const url = `${environment.domain}/lr/filterData`;

    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => { },
          error: error => { },
          complete: () => { }
        })
      );
  }
  getlrForSupervisor(ClientList: any) {
    let option = { headers: this.getHeader() }
    return this.http.post(this.baseUrl + '/lr/getlrForSupervisor/', { ClientList }, option);
  }
  getAllDispense() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/mydashboard/getDispense', option);
  }
  getDispenseGroup() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/manageExpense/getDispenseGroup', option);
  }
  getAllExpense() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/mydashboard/getExpense', option);
  }
  getSummary() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/mydashboard/getSummary', option);
  }
  getLRExpenseMonthWise() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/mydashboard/getLRExpenseMonthWise', option);
  }
  getDispenseMonthWise() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/mydashboard/getDispenseMonthWise', option);
  }
  getExpenseMonthWise() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/mydashboard/getExpenseMonthWise', option);
  }
  getLRExpenseParticularMonth() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/mydashboard/getLRExpenseParticularMonth', option);
  }
  getMasterData() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/manageExpenseLog/getMasterData', option);
  }
  getMasterDataGroup() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/manageExpenseLog/getMasterDataGroup', option);
  }
  getMasterDataCategoryGroup(){
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/manageExpenseLog/getMasterDataCategoryGroup' ,option);
  }
  getMasterDataSubCategoryGroup(){
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/manageExpenseLog/getMasterDataSubCategoryGroup' ,option);
  }
  deleteExpense(_id): Observable<{ status: any, message: any }> {
    let option = { headers: this.getHeader() }
    const url = `${environment.domain}/manageExpenseLog/deleteExpense`;
    return this.http.post<{ status: any, message: any }>(url, { _id }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getParticularExpense(id: any): Observable<{ status: any, message: any, data: any }> {
    let option = { headers: this.getHeader() }
    const url = `${environment.domain}/manageExpenseLog/particlarExpense/` + id;
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  updateOfficeExpence(formData): Observable<{ status: any, message: any }> {
    let option = { headers: this.getHeader() }
    const url = `${environment.domain}/manageExpenseLog/updateOfficeExpence`;

    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  updateVehicleMaintence(formData): Observable<{ status: any, message: any }> {
    let option = { headers: this.getHeader() }
    const url = `${environment.domain}/manageExpenseLog/updateVehicleMaintence`;

    return this.http.post<{ status: any, message: any }>(url, formData, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }
  getInvoiceTypes() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/invoice/getInvoiceType', option);
  }
  getInvoice() {
    let option = { headers: this.getHeader() }
    return this.http.get(this.baseUrl + '/invoice/getInvoiceNew', option);
  }
  getParticularInvoice(id: any): Observable<{ status: any, message: any, data: any }> {
    const url = `${environment.domain}/invoice/getInvoiceByID/` + id;
    let option = { headers: this.getHeader() }
    return this.http.get<{ status: any, message: any, data: any }>(url, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any, data: any }) => {
          },
          error: error => {
          },
          complete: () => {
          }
        })
      );
  }

  generateInvoice({ form }): Observable<{ status: any, message: any }> {
    let option = { headers: this.getHeader() }
    const url = `${environment.domain}/invoice/generateInvoice`;

    return this.http.post<{ status: any, message: any }>(url, { form }, option)
      .pipe(
        tap({
          next: (val: { status: any, message: any }) => { },
          error: error => { },
          complete: () => { }
        })
      );
  }
  sendInvoice(id: any, binary: any) {
    let option = { headers: this.getHeader() }
    return this.http.post(this.baseUrl + '/lr/sendInvoice/' + id, { binary }, option);
  }
  getSubCategory() {
    let option = { headers: this.getHeader() }
    return this.http.get(`${environment.domain}/manageExpenseLog/getSubCategory`, option);
  }
}