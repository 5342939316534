import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
import { DataTableDirective } from 'angular-datatables';
import { __await } from 'tslib';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {
  dtOptions: any = {};
  receipts: any[] = [];
  genLr: any[] = [];
  EmailLr: any[] = []
  lrByEmail: any[] = [];
  invoiceType: any;
  clientList: any[] = [{}];
  invoiceList: any = [{}];
  lrNumber: any[] = [];
  dropdownSettings
  generatedLrs: any = [];
  actualData;
  clientName: any = [];
  extractLrNoFromActualData = [];
  filterform = new FormGroup({
    clientName: new FormControl('', [Validators.required]),
    invoiceType: new FormControl('', [Validators.required]),
    LR_no: new FormControl('', [Validators.required])

  });
  invtot: any;
  tooltaxPaidtot: any;
  extrahour: any;
  kmtot: any;
  users: any=[];
  displayedColumns: any = ['sno', 'clientName', 'created_date', 'action']
  @ViewChild('paginator') paginator: MatPaginator;
  dataSource:  MatTableDataSource<any>;
  @ViewChild('printBtn') printBtn: ElementRef<HTMLElement>;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService, private spinner:NgxSpinnerService) { }
  
  
  ngOnInit(): void {
    this.spinner.show();
    this.getlrs();
    this.getInvoiceTypes();
    this.getClientList();
    this.lrNumber = [];
    // this.dtOptions = {
    //         pagingType: 'full_numbers'
    //       };
    this.filterform.controls['LR_no'].setValue(null)
  }
  ngAfterViewInit() {
  }
  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          
          var rawdata = res.data;
          this.clientList = rawdata;
          let userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
          let userroleid = userSessionData.user_role_id[0];
          if (userroleid != 1) {
            let clients = userSessionData.clientList;
            this.clientList = clients.map(email => {
            return this.clientList.filter(item=>item.clientEmail==email)[0]
          });
          }
          this.superAdminService.getInvoice().subscribe((invoices: { status: any, message: any, data: any }) => {

            if (invoices.status == true) {
              this.spinner.hide();
              let newrawdata = invoices.data;
              this.invoiceList = newrawdata;
              let temp = []
              this.invoiceList.map((x) => {
                this.clientList.map((y) => {
                  if (x.clientId == y._id) temp.push({ "clientName": y.clientName, "date": x.created_date.split("T")[0], "_id": x._id, "created_date": x.created_date })
                })
              })
              this.clientName = temp;
              this.dataSource = new MatTableDataSource(this.clientName);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }
            else {
              if (res.status == 401) {
                this.toastr.error(res.message);
                this.spinner.hide();
                window.sessionStorage.clear();
                this.router.navigateByUrl('superAuth/login');
      
              } else {
                this.toastr.error(res.message);
                this.spinner.hide();
              }
            }
          }
          );
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            this.spinner.hide();
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.spinner.hide();
            this.toastr.error(res.message);
          }
        }

      });

  }
  getInvoiceTypes() {
    this.superAdminService.getInvoiceTypes()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.invoiceType = rawdata;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
          } else {
            this.toastr.error(res.message);
          }
        }

      });

  }
  getlrs() {

    this.superAdminService.getlrs()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          // this.receipts = rawdata;

          this.receipts = rawdata.sort(function (a: any, b: any) {
            // console.log('<any>new Date(b.receipt.created_date) => ', <any>new Date(b.receipt.created_date));
            return <any>new Date(b.receipt.created_date) - <any>new Date(a.receipt.created_date);
          });
          // console.log("RR => ", this.receipts);
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      });
  }
  getlrsbynames(email) {

    this.superAdminService.getlrsbyClientId(email)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          // this.receipts = rawdata;
          //  console.log("these are the lrs by email", rawdata)
          this.lrByEmail = rawdata
          // this.lrByEmail = rawdata.sort(function (a: any, b: any) {
          //   // console.log('<any>new Date(b.receipt.created_date) => ', <any>new Date(b.receipt.created_date));
          //   return <any>new Date(b.receipt.created_date) - <any>new Date(a.receipt.created_date);
          // });

        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      });

  }
  getObjectListFromData(ids) {
    return this.lrNumber.filter(item => ids.includes(item.item_id))
  }
  generateInvoice() {

    // console.log('Actual data ', this.getObjectListFromData(this.filterform.value.LR_no.map(item => item.item_id)));
    this.extractLrNoFromActualData =[];
    this.actualData = this.getObjectListFromData(this.filterform.value.LR_no.map(item => item.item_id))
    this.actualData.map(x => {
      this.extractLrNoFromActualData.push(x.item_text)
    })
    // this.extractLrNoFromActualData = 


    if (this.filterform.valid) {

      this.removevalidatorcss("clientNamectrl");
      this.removevalidatorcss("invoiceTypectrl");
      this.removevalidatorcss("LR_noctrl");
      var form = {
        "clientId": this.filterform.get("clientName").value,
        "invoiceType": this.filterform.get("invoiceType").value,
        // "LR_no": this.filterform.get("LR_no").value
        "LR_no": this.extractLrNoFromActualData
      }
      this.superAdminService.generateInvoice({ form })
        .subscribe((res: { status: any, message: any, data: any }) => {

          if (res.status == true) {
            var rawdata = res.data;
            this.toastr.success(res.message);
            this.ngOnInit();
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
          }

        });
    } else {
      if (this.filterform.get('clientName').invalid) {
        this.addvalidatorcss("clientNamectrl");
      } else {
        this.removevalidatorcss("clientNamectrl");
      }
      if (this.filterform.get('invoiceType').invalid) {
        this.addvalidatorcss("invoiceTypectrl");
      } else {
        this.removevalidatorcss("invoiceTypectrl");
      }
      if (this.filterform.get('LR_no').invalid) {
        this.addvalidatorcss("LR_noctrl");
      } else {
        this.removevalidatorcss("LR_noctrl");
      }
    }
  }
  onItemSelect($event) {
  }

  async onFocusGetLr(event: any) {
    let id = event.target.value
    this.superAdminService.getlrsbyClientId(id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          rawdata = rawdata.filter((item)=> item.tripEndDate);
          this.EmailLr = []
          rawdata.map(x => {
            this.EmailLr.push(x.LR_no)
          })


          this.generatedLrs = []
          this.invoiceList.map(x => {
            x.invoice.map(y => {
              this.generatedLrs.push(y.LR_no)
            })
          })

          this.genLr = []

          for (let i = 0; i < this.generatedLrs.length; i++) {
            for (let j = 0; j < this.generatedLrs[i].length; j++) {
              this.genLr.push(this.generatedLrs[i][j])
            }
          }



          let temp = []

          //  for(let i=0; i<this.EmailLr.length; i++){
          //   var bool =false;
          //    if(this.genLr.length>0){
          //      for(let j=0; j<this.genLr.length; j++){
          //        if(this.EmailLr[i] !=this.genLr[j]){
          //          bool = true
          //        }
          //      }
          //      if(bool == true){
          //       this.lrNumber.push(this.EmailLr[i]);
          //      }
          //    }else{
          //      this.lrNumber.push(this.EmailLr[i]);
          //    }

          //  }

          for (let i = 0; i < this.EmailLr.length; i++) {
            var bool = false;
            for (let j = 0; j < this.genLr.length; j++) {
              if (this.EmailLr[i] == this.genLr[j]) {
                bool = true
              }
            }
            if (bool == false) {
              temp.push({ item_id: i, item_text: this.EmailLr[i] });
            }
          }
          this.lrNumber = temp;

          this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All'

          };

        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      });



    // this.filterform.patchValue({
    //   LR_no: this.lrNumber
    // });
  }
  backTo() {
    window.history.back()
  }
  addvalidatorcss(id: string) {
    // document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    // document.getElementById(id).classList.remove('validatorcss');
  }
  deleteInvoice(_id) {
    if (this.superAdminService.deleteDailogBox()) {
      this.superAdminService.deleteInvoice(_id)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            this.toastr.success(res.message);
            // this.ngOnInit();
            this.ngOnInit()
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
          }

        }, error => {
        });
    }
  }
  getParticularInvoice(id: string) {
    this.superAdminService.getParticularInvoice(id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.users = rawdata[0].invoice;

          this.users.forEach((value: any) => {
            let balancepaidArray = value.balancePaid;
            this.invtot = balancepaidArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let up_down_kmArray = value.up_down_km;
            this.kmtot = up_down_kmArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let extrahourArray = value.extrahour;
            this.extrahour = extrahourArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let tollArray = value.tooltaxPaid;
            this.tooltaxPaidtot = tollArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)
            //console.log("balancePaid",this.invtot);
            setTimeout(()=>{
              let printBtn: HTMLElement = this.printBtn.nativeElement;
              printBtn.click();
            });
          });
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }
  sendInvoice(divName, Id) {
    this.superAdminService.getParticularInvoice(Id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.users = rawdata[0].invoice;

          this.users.forEach((value: any) => {
            let balancepaidArray = value.balancePaid;
            this.invtot = balancepaidArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let up_down_kmArray = value.up_down_km;
            this.kmtot = up_down_kmArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let extrahourArray = value.extrahour;
            this.extrahour = extrahourArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let tollArray = value.tooltaxPaid;
            this.tooltaxPaidtot = tollArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

          });
          // send invoice begins
          setTimeout(()=>{
            var data = document.getElementById(divName);
            data.style.display = 'block';
            html2canvas(data).then(canvas => {
              var imgWidth = 208;
              var imgHeight = canvas.height * imgWidth / canvas.width;
              const contentDataURL = canvas.toDataURL('image/png', 0.3)
              let pdf = new jsPDF('p', 'mm', 'a4');
              pdf.addImage(contentDataURL, 'PNG', 1, 1, imgWidth, imgHeight, undefined, 'FAST');
              let binary = pdf.output();
              data.style.display = 'none';
              binary = binary ? btoa(binary) : '';
              this.superAdminService.sendInvoice(Id, binary).subscribe((res: any) => {
                if (res.status == true) {
                  this.toastr.success(res.message);
                }
                else {
                  if (res.status == 401) {
                    this.toastr.error(res.message);
                    window.sessionStorage.clear();
                    this.router.navigateByUrl('superAuth/login');

                  } else {
                    this.toastr.error(res.message);
                  }
                }
              }, error => {
              });
            });
          })
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }
  filterData(value){
    this.dataSource.filter = value;
  }
  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'clientName':
          return compare(lower(a.clientName), lower(b.clientName), isAsc);
        case 'created_date':
          return compare(a.created_date, b.created_date, isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string , b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value){
      return value?value.toLowerCase():value;
    }
  }
}

