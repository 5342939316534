<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">Edit LR</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button (click)="backTo()"><i
                        class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LR </li>
                    <li class="breadcrumb-item active" aria-current="page">Edit LR</li>

                </ol>
            </nav>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="editLR">
                <div class="contentbox">
                    <div class="row account">
                        <div class="col-md-12 border-bottom pb-3 border-gray mb-3">
                            <h4 class="text-dark fw-bold mb-0">Basic Details ({{ this.particlarLR?.LR_no }})</h4>
                        </div>

                        <div class="row">
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Supervisor Name </label>
                                    <select id="supervisornamectrl" formControlName="supervisorname" placeholder="Select Status"
                                        class="form-control shadow-none">
                                        <option selected disabled value="null" >--Select Supervisor Name--</option>
                                        <option *ngFor="let s of superVisor" value="{{s._id}}">{{s.full_name}}</option>
                                    </select>
        
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Company Name</label>

                                    <select *ngIf="isRoleSuperVisor" id="clientListctrl" formControlName="clientList"
                                        placeholder="Select Company Name" class="form-control shadow-none">
                                        <!-- <option   value="">--Select Client Name--</option> -->
                                        <option *ngFor="let cl of clientList" value="{{cl._id}}">
                                            {{cl.clientName}}
                                        </option>

                                    </select>
                                    <select disabled *ngIf="!isRoleSuperVisor" id="clientListctrl"
                                        formControlName="clientList" placeholder="Select Client Name"
                                        class="form-control shadow-none">
                                        <!-- <option   value="">--Select Client Name--</option> -->
                                        <option *ngFor="let cl of clientList" value="{{cl._id}}">
                                            {{cl.clientName}}
                                        </option>

                                    </select>

                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">System LR No.</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none" id="LR_noctrl"
                                            placeholder="Enter System LR No." disabled formControlName="LR_no">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Manual LR No.</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none" id="manualLR_noctrl"
                                            placeholder="Enter Manual LR No." formControlName="manualLR_no">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Date</label>
                                    <div class="d-flex align-items-center">
                                        <input *ngIf="isRoleSuperVisor" type="date"
                                            class="form-control me-1 shadow-none" id="datectrl" placeholder="Enter date"
                                            formControlName="date">
                                        <input disabled *ngIf="!isRoleSuperVisor" type="date"
                                            class="form-control me-1 shadow-none" id="datectrl" placeholder="Enter date"
                                            formControlName="date">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Consignee Name</label>
                                    <div class="d-flex align-items-center">
                                        <input *ngIf="isRoleSuperVisor" type="text"
                                            class="form-control me-1 shadow-none" id="ConsigneeNamectrl"
                                            placeholder="Enter Consignee Name" formControlName="ConsigneeName">

                                        <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                            class="form-control me-1 shadow-none" id="ConsigneeNamectrl"
                                            placeholder="Enter Consignee Name" formControlName="ConsigneeName">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Consignee Address</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="ConsigneeAddressctrl"
                                        class="form-control shadow-none" placeholder="Enter Consignee Address"
                                        formControlName="ConsigneeAddress">

                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="ConsigneeAddressctrl"
                                        class="form-control shadow-none" placeholder="Enter Consignee Address"
                                        formControlName="ConsigneeAddress">

                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Consignor Name</label>
                                    <div class="d-flex align-items-center">
                                        <input *ngIf="isRoleSuperVisor" type="text"
                                            class="form-control me-1 shadow-none" id="ConsignorNamectrl"
                                            placeholder="Enter Consignor Name" formControlName="ConsignorName">
                                        <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                            class="form-control me-1 shadow-none" id="ConsignorNamectrl"
                                            placeholder="Enter Consignor Name" formControlName="ConsignorName">


                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Consignor Address</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="ConsignorAddressctrl"
                                        class="form-control shadow-none" placeholder="Enter Consignor Address"
                                        formControlName="ConsignorAddress">
                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="ConsignorAddressctrl"
                                        class="form-control shadow-none" placeholder="Enter Consignor Address"
                                        formControlName="ConsignorAddress">

                                </div>
                            </div>

                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Vehicle No.</label>
                                    <input type="text" id="VehicleNoctrl"
                                        class="form-control shadow-none" placeholder="Enter Vechicle No."
                                        formControlName="VehicleNo"
                                        [matAutocomplete]="autoVehicleNo"
                                        >
                                    <mat-autocomplete autoActiveFirstOption #autoVehicleNo="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredVehicleNo | async" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <!-- <input disabled *ngIf="!isRoleSuperVisor" type="text" id="VehicleNoctrl"
                                        class="form-control shadow-none" placeholder="Enter Vechicle No."
                                        formControlName="VehicleNo"> -->

                                    <!-- <select id="VehicleNoctrl"   formControlName="VehicleNo" name="" class="form-control shadow-none">
                                                <option  value="">--Select Vehicle No.--</option>
                                                <option *ngFor= "let t of vehicles"  value="{{ t.vehicle_no}}">{{ t.vehicle_no}}</option> 
                                                <option   value="{{ this.particlarLR.vehicle_id}}">{{ this.vehicles.vehicle_no}}</option>
                                                <option   value="{{ this.vehicles._id}}">{{ this.vehicles.vehicle_no}}</option>  

                                            </select>  -->

                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Vehicle Type</label>
                                    <input type="text" id="vehicleTypectrl"
                                        class="form-control shadow-none" placeholder="Enter Vehicle Type"
                                        formControlName="vehicleType"
                                        [matAutocomplete]="autoVehicleType"
                                    >
                                    <mat-autocomplete autoActiveFirstOption #autoVehicleType="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredVehicleTypes | async" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <!-- <input disabled *ngIf="!isRoleSuperVisor" type="text" id="vehicleTypectrl"
                                        class="form-control shadow-none" placeholder="Enter Vehicle Type"
                                        formControlName="vehicleType"> -->

                                    <!-- <select id="vehicleTypectrl" formControlName="vehicleType" placeholder="Select Vehicle Type" class="form-control shadow-none">
                                                <option   value="">--Select Vehicle Type--</option>
                                                <option *ngFor="let c of vehicletypes" value="{{c.vehicletype_name}}">{{c.vehicletype_name}}</option> 
                                                
                                            </select> -->

                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">From</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="fromctrl"
                                        class="form-control shadow-none" placeholder="Enter From"
                                        formControlName="from">
                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="fromctrl"
                                        class="form-control shadow-none" placeholder="Enter From"
                                        formControlName="from">

                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">GSTIN</label>
                                    <div class="d-flex align-items-center">
                                        <input *ngIf="isRoleSuperVisor" type="text"
                                            class="form-control me-1 shadow-none" id="GSTINctrl"
                                            placeholder="Enter GSTIN" formControlName="GSTIN">
                                        <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                            class="form-control me-1 shadow-none" id="GSTINctrl"
                                            placeholder="Enter GSTIN" formControlName="GSTIN">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">To</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="toctrl"
                                        class="form-control shadow-none" placeholder="Enter To" formControlName="to">
                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="toctrl"
                                        class="form-control shadow-none" placeholder="Enter To" formControlName="to">
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Road Permit No.</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="RoadPermitNoctrl"
                                        class="form-control shadow-none" placeholder="Enter Road Permit No."
                                        formControlName="RoadPermitNo">
                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="RoadPermitNoctrl"
                                        class="form-control shadow-none" placeholder="Enter Road Permit No."
                                        formControlName="RoadPermitNo">
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">State Code</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="StateCodectrl"
                                        class="form-control shadow-none" placeholder="Enter State Code"
                                        formControlName="StateCode">
                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="StateCodectrl"
                                        class="form-control shadow-none" placeholder="Enter State Code"
                                        formControlName="StateCode">
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">DLY</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="DLYctrl"
                                        class="form-control shadow-none" placeholder="Enter DLY" formControlName="DLY">
                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="DLYctrl"
                                        class="form-control shadow-none" placeholder="Enter DLY" formControlName="DLY">
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Declared Value</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="DeclaredValuectrl"
                                        class="form-control shadow-none" placeholder="Enter Declared Value" disabled
                                        formControlName="DeclaredValue">
                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="DeclaredValuectrl"
                                        class="form-control shadow-none" placeholder="Enter Declared Value" disabled
                                        formControlName="DeclaredValue">
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">FIX / Adhoc</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="fixAdhocctrl"
                                        class="form-control shadow-none" placeholder="Enter FIX / Adhoc Value"
                                        formControlName="fixAdhoc">
                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="fixAdhocctrl"
                                        class="form-control shadow-none" placeholder="Enter FIX / Adhoc Value"
                                        formControlName="fixAdhoc">
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Quantity/Weight</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="quantityctrl"
                                        class="form-control shadow-none" placeholder="Enter Quantity/Weight"
                                        formControlName="quantity">
                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="quantityctrl"
                                        class="form-control shadow-none" placeholder="Enter Quantity/Weight"
                                        formControlName="quantity">
                                </div>
                            </div>

                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Transporter Name</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="transportorNamectrl"
                                        class="form-control shadow-none" placeholder="Enter Transporter Name"
                                        formControlName="transportorName">

                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="transportorNamectrl"
                                        class="form-control shadow-none" placeholder="Enter Transporter Name"
                                        formControlName="transportorName">
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Transporter Mobile</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="transportorMobilectrl"
                                        class="form-control shadow-none" placeholder="Enter Transporter Mobile"
                                        formControlName="transportorMobile">

                                    <input disabled *ngIf="!isRoleSuperVisor" type="text" id="transportorMobilectrl"
                                        class="form-control shadow-none" placeholder="Enter Transporter Mobile"
                                        formControlName="transportorMobile">
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Driver Name</label>
                                    <input type="text" id="driverNamectrl"
                                        class="form-control shadow-none" placeholder="Enter Driver Name"
                                        formControlName="driverName"
                                        [matAutocomplete]="autoDriverName"
                                        >
                                    <mat-autocomplete autoActiveFirstOption #autoDriverName="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredDriverName | async" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <!-- <input disabled *ngIf="!isRoleSuperVisor" type="text" id="driverNamectrl"
                                        class="form-control shadow-none" placeholder="Enter Driver Name"
                                        formControlName="driverName"> -->
                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Driver Mobile</label>
                                    <input *ngIf="isRoleSuperVisor" type="text" id="driverMobilectrl"
                                        class="form-control shadow-none" placeholder="Enter Driver Mobile"
                                        formControlName="driverMobile"
                                        [matAutocomplete]="autoDriverNumber">
                                    <mat-autocomplete autoActiveFirstOption #autoDriverNumber="matAutocomplete">
                                        <mat-option *ngFor="let option of filteredDriverNumber | async" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <!-- <input disabled *ngIf="!isRoleSuperVisor" type="text" id="driverMobilectrl"
                                        class="form-control shadow-none" placeholder="Enter Driver Mobile"
                                        formControlName="driverMobile"> -->

                                </div>
                            </div>
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Freight</label>
                                    <div class="d-flex align-items-center">
                                        <input *ngIf="isRoleSuperVisor" type="text"
                                            class="form-control me-1 shadow-none" placeholder="Enter Freight Value"
                                            id="Frieghtctrl" formControlName="Frieght">
                                        <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                            class="form-control me-1 shadow-none" placeholder="Enter Freight Value"
                                            id="Frieghtctrl" formControlName="Frieght">
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Date</label>
                                        <input type="date" id="datectrl" class="form-control shadow-none"
                                            placeholder="Enter Date"   formControlName="date">
                                    </div>
                            </div> -->
                            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Loading Slip Attachment</label>
                                    <div class="d-flex align-items-center">
                                        <input type="file" accept="image/*,.pdf,.doc"
                                            (change)="fileChangeEventtollAttachment($event)"
                                            class="form-control me-1 shadow-none" id="tollAttachmentctrl"
                                            formControlName="tollAttachment" multiple>
                                        <button (click)="addTollFiles()" mat-raised-button class="btn btn-dark"
                                            type="submit"><i class="fa-solid fa-upload"></i></button>
                                        <button class="btn btn-dark btn-sm  px-4 ms-3" type="button" (click)="previewLoadingSlip()" mat-raised-button >Preview</button>
                                        <a #showLoadingSlipPreview [href]="previewLoadingSlipURL" target="_blank"></a>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                </div>
                <div class="contentbox mt-3">
                    <div class="row account">
                        <div class="col-md-12 border-bottom pb-3 border-gray mb-3">
                            <h4 class="text-dark fw-bold mb-0">Information For office use only</h4>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Starting Meter Reading</label>
                                        <div class="d-flex align-items-center">
                                            <input *ngIf="isRoleSuperVisor" type="number"
                                                class="form-control me-1 shadow-none" placeholder="Enter Meter Reading"
                                                id="startmeterReadingctrl" formControlName="startmeterReading">
                                            <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                                class="form-control me-1 shadow-none" placeholder="Enter Meter Reading"
                                                id="startmeterReadingctrl" formControlName="startmeterReading">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">End Meter Reading</label>
                                        <div class="d-flex align-items-center">
                                            <input (focusout)="onFocusOutendmeterReading($event)" type="number"
                                                class="form-control me-1 shadow-none" placeholder="Enter  Meter Reading"
                                                id="endmeterReadingctrl" formControlName="endmeterReading">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Trip Starting Date</label>
                                        <div class="d-flex align-items-center">
                                            <input *ngIf="isRoleSuperVisor" type="date"
                                                class="form-control me-1 shadow-none" placeholder="Enter Start Time"
                                                id="tripSDatectrl" formControlName="tripSDate">
                                            <input disabled *ngIf="!isRoleSuperVisor" type="date"
                                                class="form-control me-1 shadow-none" placeholder="Enter Start Time"
                                                id="tripSDatectrl" formControlName="tripSDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Trip End Date</label>
                                        <div class="d-flex align-items-center">
                                            <input type="date" class="form-control me-1 shadow-none"
                                                placeholder="Enter End Time" id="tripEDatectrl"
                                                formControlName="tripEDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Trip Starting Time</label>
                                        <div class="d-flex align-items-center">
                                            <input *ngIf="isRoleSuperVisor" type="time"
                                                class="form-control me-1 shadow-none" placeholder="Enter Start Time"
                                                id="outtimectrl" formControlName="outtime">
                                            <input disabled *ngIf="!isRoleSuperVisor" type="time"
                                                class="form-control me-1 shadow-none" placeholder="Enter Start Time"
                                                id="outtimectrl" formControlName="outtime">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Trip End Time</label>
                                        <div class="d-flex align-items-center">
                                            <input type="time" class="form-control me-1 shadow-none"
                                                placeholder="Enter End Time" id="INtimectrl" formControlName="INtime">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Number of Tolls</label>
                                        <div class="d-flex align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter Tolls" id="noofToolsctrl"
                                                formControlName="noofTools">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Total Toll Tax Paid</label>
                                        <div class="d-flex align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Enter Tolls Tax Paid" id="tooltaxPaidctrl"
                                                formControlName="tooltaxPaid">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Labour Loading / Labour Unloading</label>
                                        <div class="d-flex align-items-center">
                                            <input *ngIf="isRoleSuperVisor" type="number"
                                                class="form-control me-1 shadow-none" placeholder="Labour Paid"
                                                id="labourPaidctrl" formControlName="labourPaid">
                                            <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                                class="form-control me-1 shadow-none" placeholder="Labour Paid"
                                                id="labourPaidctrl" formControlName="labourPaid">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Other payment</label>
                                        <div class="d-flex align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Other payment" id="otherPaymentctrl"
                                                formControlName="otherPayment">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Usage KM</label>
                                        <div class="d-flex align-items-center">
                                            <input disabled *ngIf="isRoleSuperVisor" type="text"
                                                class="form-control me-1 shadow-none" placeholder="KM" id="updownkmctrl"
                                                formControlName="updownkm">
                                            <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                                class="form-control me-1 shadow-none" placeholder="KM" id="updownkmctrl"
                                                formControlName="updownkm">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Total Hours</label>
                                        <div class="d-flex align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Total Hours" id="totalhourctrl"
                                                formControlName="totalhour">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Extra Hours</label>
                                        <div class="d-flex align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Extra Hours" id="extrahourctrl"
                                                formControlName="extrahour">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Detention</label>
                                        <div class="d-flex align-items-center">

                                            <input *ngIf="isRoleSuperVisor" type="number"
                                                class="form-control me-1 shadow-none" placeholder="Detention"
                                                id="detentionctrl" formControlName="detention">
                                            <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                                class="form-control me-1 shadow-none" placeholder="Detention"
                                                id="detentionctrl" formControlName="detention">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Vehicle Rate</label>
                                        <div class="d-flex align-items-center">
                                            <input *ngIf="isRoleSuperVisor" type="number"
                                                class="form-control me-1 shadow-none" placeholder="Vehicle Rate"
                                                id="vehicleRatectrl" formControlName="vehicleRate">
                                            <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                                class="form-control me-1 shadow-none" placeholder="Vehicle Rate"
                                                id="vehicleRatectrl" formControlName="vehicleRate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Advance</label>
                                        <div class="d-flex align-items-center">
                                            <input *ngIf="isRoleSuperVisor" type="number"
                                                class="form-control me-1 shadow-none" placeholder="Advance"
                                                id="advancectrl" formControlName="advance">

                                            <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                                class="form-control me-1 shadow-none" placeholder="Advance"
                                                id="advancectrl" formControlName="advance">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Actual Balance</label>
                                        <div class="d-flex align-items-center">

                                            <input *ngIf="isRoleSuperVisor" type="number"
                                                class="form-control me-1 shadow-none" placeholder="Actual Balance"
                                                id="balancectrl" formControlName="balance">
                                            <input disabled *ngIf="!isRoleSuperVisor" type="text"
                                                class="form-control me-1 shadow-none" placeholder="Balance"
                                                id="balancectrl" formControlName="balance">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                                    <div class="form-group accountsinp  mb-3">
                                        <label for="">Balance Paid</label>
                                        <div class="d-flex align-items-center">
                                            <input type="number" class="form-control me-1 shadow-none"
                                                placeholder="Balance Paid" disabled (focusout)="onFocusOutEvent($event)"
                                                id="balancePaidctrl" formControlName="balancePaid">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 text-end">
                                    <button (click)="updateLRSubmit()" class="btn btn-dark" type="submit"
                                        mat-raised-button>Save</button>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>

            </form>
        </div>
        <div class="col-xl-12">
            <div class="contentbox mt-3">
                <div class="row account">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="d-flex align-items-baseline justify-content-between">
                                    <h1 class="fs-4 fw-bold mb-0">Invoice Details</h1>
                                    <button class="btn btn-dark btn-sm " mat-raised-button (click)="addnewinv(this.particlarLR?._id)"> + Add New Invoice</button>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-12 mt-3 table-responsive">
                        <table datatable class="tablelist" *ngIf='this.particlarLR?.invoice_no.length > 0'>
                            <thead>
                                <tr>
    
                                    <td>NO of Article</td>
                                    <td>Invoice No.</td>
                                    <td>Customer Name</td>
                                    <td>Invoice Value</td>
                                    <td>Weight</td>
                                    <!-- <td>Frieght</td> -->
    
                                    <td>ACTION</td>
                                </tr>
                            </thead>
                            <tbody>
    
    
                                <tr *ngFor="let inv of this.particlarLR?.invoice_no">
                                    <td>{{ inv.NoofArticle}}</td>
                                    <td>{{ inv.InvoiceNo}}</td>
                                    <td>{{ inv.customerName}}</td>
                                    <td>{{ inv.InvoiceValue}}</td>
                                    <td>{{ inv.Weight}}</td>
    
                                    <!-- <td>{{ inv.Frieght}}</td>  -->
                                    <td><button (click)="editInvoice(inv,this.particlarLR?._id)"
                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Edit" class="fas fa-pen"></i></button>
                                    </td>
                                </tr>
    
                            </tbody>
    
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>