import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { MatPaginator } from '@angular/material';
import { SuperAdminService } from '../../../super-admin.service';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.css']
})
export class NewInvoiceComponent implements OnInit {
  invtot:any;
  tooltaxPaidtot:any;
  extrahour:any;
  kmtot:any;
  users: any;
  paramId;
  constructor(private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,private router: Router,
    private superAdminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getParticularInvoice(this.paramId);
    })
  }


  ngOnInit(): void {
    //this.getParticularInvoice();
  }
  getParticularInvoice(id: string) {
    this.superAdminService.getParticularInvoice(id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.users = rawdata[0].invoice;

          this.users.forEach( (value:any)=> {
            let balancepaidArray = value.balancePaid;
            this.invtot =  balancepaidArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)

            let up_down_kmArray = value.up_down_km;
            this.kmtot =  up_down_kmArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)

            let extrahourArray = value.extrahour;
            this.extrahour =  extrahourArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)

             let tollArray = value.tooltaxPaid;
             this.tooltaxPaidtot =  tollArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)
          }); 
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }
  sendInvoice(divName) {
    var data = document.getElementById(divName);
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png', 0.3)
      let pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 1, 1, imgWidth, imgHeight, undefined, 'FAST');
      let binary = pdf.output();
      binary = binary ? btoa(binary) : '';
      this.superAdminService.sendInvoice(this.paramId, binary).subscribe((res: any) => {
        if (res.status == true) {
          this.toastr.success(res.message);
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }
      }, error => {
        console.log('error:');
        console.log(error);
      });
    });

  }
  backTo() {
    window.history.back()
  }

}
