<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-6 my-auto    ">
            <nav aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/manageExpenseLog">Manage Expense Log</li>
                    <li class="breadcrumb-item active" aria-current="page">Add New Expense Log</li>

                </ol>
            </nav>
        </div>
        <div class="col-xl-6 col-12 col-sm-12 col-md-12">
            <ul class="addaccount">
                <li class="my-auto"><button (click)="backTo()" class="btn btn-primary btn-sm text-white px-4"><i
                            class="fas fa-arrow-left"></i> Back</button></li>
            </ul>
        </div>

    </div>

    <div class="row">


        <!-- <div class="col-lg-4 col-xl-4 col-sm-4">


            <p>Hire Vehicle Flat</p>
            <a [routerLink]="['/superAdmin/manageExpenseLog/hireVicheleFlat']">
                <img src="../../../../assets/images/icons/Hire-Vehicle-Flat.png">
            </a>

        </div>
        <div class="col-lg-4 col-xl-4 col-sm-4">


            <p>Hire Vehicle Monthly
            <p>
                <a [routerLink]="['/superAdmin/manageExpenseLog/hireVicheleMonthly']">
                <img src="../../../../assets/images/icons/Hire-Vehicle-Monthly.png">
                </a>
        </div> -->
        <div class="col-lg-4 col-xl-4 col-sm-4">


            <p>Office Expense</p>
            <a [routerLink]="['/superAdmin/manageExpenseLog/officeExpense']">
              <img src="../../../../assets/images/icons/Office-expanses.png">
            </a>

        </div>
        <div class="col-lg-4 col-xl-4 col-sm-4">


            <p>Vehicle Maintenance</p>
            <a [routerLink]="['/superAdmin/manageExpenseLog/vehicleMaintenence']">
            <img src="../../../../assets/images/icons/Vehicle-Maintenance.png">
            </a>
        </div>
        
        <div class="col-lg-4 col-xl-4 col-sm-4">


            <p>Our Own Vehicle Expense</p>
            <a [routerLink]="['/superAdmin/manageExpenseLog/ourownVehicleExpense']">
            <img src="../../../../assets/images/icons/Our-Own-Vehicle-Expense.png">
            </a>

        </div>


    </div>
</div>