<div class="container-fluid">
    <div class="row manageaccount mt-3">
        
        <div class="col-md-12">
            <div class="d-flex align-items-baseline justify-content-between">

                <h1 class="fs-4 fw-bold mb-0">Update User</h1>
    
    
                <button class="btn btn-dark btn-sm "  matTooltip="Back"
                matTooltipClass="example-tooltip-uppercase" matTooltipPosition="left" mat-raised-button (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                  <li class="breadcrumb-item" routerLink="/superAdmin/usermanagement">User Management</li>
                  <li class="breadcrumb-item active" aria-current="page">Update User</li>

                </ol>
              </nav>
        </div>
       

    </div>
   

    <div class="row  mt-3">
        <div class="col-xl-12">
            <form [formGroup]="updateUser" (ngSubmit)="updateUserSubmit()">
                <div class="contentbox">
                <div class="row account mb-3">
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp mb-3">
                          <label for="">Title</label>
                          <select formControlName="title" id="titlectrl" class="form-control shadow-none" (change)="onKeyTitle($event)">
                            <option value="select">--Select Title--</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Miss.">Miss.</option>
                          </select>
                          <span class="text-danger" *ngIf="ff.title.invalid  && ff.title.errors.required && (ff.title.touched || submitClicked)">
                            Please select Title 
                          </span>
                        </div>
                      </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">First Name</label>
                            <input type="text" id="firstnamectrl" class="form-control shadow-none"
                                placeholder="Enter First Name" formControlName="firstname" (keyup)="onKeyFirstName($event)">
                            <span class="text-danger" *ngIf="!ff.firstname.value && (ff.firstname.touched || submitClicked) ">
                                Please enter First name
                            </span>
                            <span class="text-danger" *ngIf="ff.firstname.value && ff.firstname.value.length>25 && (ff.firstname.touched || submitClicked) ">
                                Only 25 characters are allowed
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Last Name</label>
                            <input type="text" id="lastnamectrl" class="form-control shadow-none"
                                placeholder="Enter Last Name" formControlName="lastname" (keyup)="onKeyLastName($event)">
                            <span class="text-danger" *ngIf="!ff.lastname.value && (ff.lastname.touched || submitClicked)">
                                Please enter Last name
                            </span>
                            <span class="text-danger" *ngIf="ff.firstname.value && ff.lastname.value.length>25 && (ff.lastname.touched || submitClicked)">
                                Only 25 characters are allowed
                            </span>
                    </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">User email</label>
                            <input disabled type="email" id="emailctrl" class="form-control shadow-none"
                                placeholder="Enter Email" formControlName="email" (keyup)="onKeyEmail($event)">
                            <span *ngIf="ff.email && ff.email.invalid && (ff.email.touched || submitClicked) " style="color
                            :red"> Please enter Email address

                            </span>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Client Name</label>
                            
                            <select  id="clientListctrl" formControlName="clientList" placeholder="Select Client Name" class="form-control shadow-none">
                                    <option    disabled value="">--Select Client Name--</option>
                                     <option  *ngFor="let cl of clientList" value="{{cl?.clientEmail}}">{{cl?.clientName}}</option> 
                                    
                            </select>

                        </div>
                    </div> -->
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4"> 
                        <!-- <form [formGroup]="form"> -->
                        <label for="">Select Company Name<sup class="text-danger">*</sup></label>
                        <div class="form-group accountsinp  mb-3">
                            <div id="clientListCtrl">
                                <ng-multiselect-dropdown formControlName="clientList" [placeholder]="'Select Company Name'"
                                    [settings]="dropdownSettings"  [data]="clientListDropDown" 
                                    (onSelect)="onItemSelect($event)" 
                                    (onDeSelect)="onItemDeselect($event)"
                                    (onDeSelectAll)="onItemDeselectAll($event)"
                                    (onSelectAll)="onItemSelectAll($event)">
                                </ng-multiselect-dropdown>
                            </div>
                            <span class="text-danger" *ngIf="ff.clientList.invalid && (ff.clientList.touched || submitClicked) ">
                                Please select the Company name
                            </span>
                        </div>
                    </div>
                    
                    
                   
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">User Mobile</label>
                            <input type="text" id="mobilectrl" class="form-control shadow-none"
                                placeholder="Enter Phone" formControlName="mobile" (keyup)="onKeyMobile($event)">
                            <span class="text-danger" *ngIf="!ff.mobile.value && (ff.mobile.touched || submitClicked)">
                                Please enter user's Mobile number
                            </span>
                            <span class="text-danger" *ngIf="ff.mobile.value && ff.mobile.value.length!=10 && (ff.mobile.touched || submitClicked)">
                                Only 10 digits are allowed
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Status</label>
                            <select id="statusctrl" formControlName="status" name="" class="form-control shadow-none">
                                <option disabled value="">--Select Status--</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                        </div>
                        <span class="text-danger" *ngIf="ff.status.invalid && (ff.status.touched || submitClicked) ">
                            Please select the Status of the User's account
                        </span>
                    </div>
                   
                    <div class="col-md-12 text-end">
                        <button class="btn btn-outline-dark" type="button" (click)="resetForm()"
                        mat-raised-button>Reset</button>
                        <button class="btn btn-dark ms-2" type="submit" mat-raised-button>Update</button>
                    
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
</div>