import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutSuperAdminComponent } from './superAdmin/main-layout/main-layout.component';
import { MydashboardModule } from './superAdmin/mydashboard/mydashboard.module';
import { MycommonSuperAdminModule } from './superAdmin/mycommon/mycommon.module';
import { UserManagementModule } from './superAdmin/user-management/user-management.module';
import { HttpClientModule } from '@angular/common/http';
import { LrManagementModule } from './superAdmin/LR-management/LR-management.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManageExpenseLogModule } from './superAdmin/manage-expense-log/manage-expense-log.module';
import { CommonModule } from '@angular/common';
// import { NgxSpinnerModule } from "ngx-spinner";

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent, MainLayoutSuperAdminComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MycommonSuperAdminModule,
    MydashboardModule,
    UserManagementModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({timeOut: 6000}),
    LrManagementModule,
    ManageExpenseLogModule,
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
