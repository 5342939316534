import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ImagePreviewComponent } from '../../LR-management/image-preview/image-preview.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-edit-vehicle-maintenance',
  templateUrl: './edit-vehicle-maintenance.component.html',
  styleUrls: ['./edit-vehicle-maintenance.component.css']
})
export class EditVehicleMaintenanceComponent implements OnInit {
  filesToUpload: Array<File> = [];
  superVisor;
  company;
  vehicletypes;
  particlarExpense;
  subCat;
  paramId;
  supervisorname=null;
  slipURL:any;
  @ViewChild('showSlip') showSlip:ElementRef;
  apiUrl= environment.domain;
  userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
  editVehicleMaintenanceForm = new FormGroup({
    supervisorname: new FormControl(null, [Validators.required]),
    expenseCategory: new FormControl('', [Validators.required]),
    companyname: new FormControl('', [Validators.required]),
    expenseDate:new FormControl('', [Validators.required]),
    expenseAmount: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\d{1,}$/)])),
    expenseDescription: new FormControl('', [Validators.required]),
    vehicleType: new FormControl('', [Validators.required]),
    vehicleNumber: new FormControl('', [Validators.required]),
    otherCharges: new FormControl('',),
    mechanicName: new FormControl('', [Validators.required]),
    mechanicNumber: new FormControl('', [Validators.required]),
    LRReceipt1: new FormControl(''), 
  })
  constructor(private activatedRoute: ActivatedRoute,private router :Router, private toastr: ToastrService, private superAdminService: SuperAdminService,public dialog: MatDialog, private sanitizer:DomSanitizer,private spinner: NgxSpinnerService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getParticularExpense(this.paramId);
    })
  }
 ngOnInit(): void {
   this.getCompanyName();
   this.getSuperVisorName();
   this.getVehicleType();
   this.getSubCat();
 }
 getSubCat(){
  this.superAdminService.getSubCategory()
  .subscribe((res: { status: any, message: any, data: any }) => {
    if (res.status == true) {
      var rawdata = res.data;
      // this.subCat = rawdata.expenseSubCategory;
      this.subCat=rawdata.filter(word => word.expenseCategory === 'Vehicle Maintenance');
      //this.dtOptions.data = this.users;
    }
    else {
      if (res.status == 401) {
        this.toastr.error(res.message);
        window.sessionStorage.clear();
        this.router.navigateByUrl('superAuth/login');
        
      }else {
        this.toastr.error(res.message);
      }
    }
  }, error => {
  });
}
 getParticularExpense(id: string) {
  this.superAdminService.getParticularExpense(id).subscribe((res: { status: any, message: any, data: any }) => {
    if (res.status == true) {
      this.particlarExpense = res.data[0];
      this.initform();
    }
    else {
      if (res.status == 401) {
        this.toastr.error(res.message);
        window.sessionStorage.clear();
        this.router.navigateByUrl('superAuth/login');
        
      }else {
        this.toastr.error(res.message);
      }
    }

  }, error => {
  });

}
initform() {
  this.editVehicleMaintenanceForm.patchValue({

    expenseDescription: this.particlarExpense.expenseDescription,
    expenseDate: new Date(this.particlarExpense.expenseDate).toISOString().split('T')[0],
    expenseAmount: this.particlarExpense.totalExpenseAmount,
    expenseCategory: this.particlarExpense.expenseSubCategoryId,
    supervisorname: this.particlarExpense.supervisorId,
    companyname: this.particlarExpense.companyId,
    vehicleType: this.particlarExpense.vehicleType,
    vehicleNumber: this.particlarExpense.vehicleNumber,
    otherCharges: this.particlarExpense.otherCharges,
    mechanicName: this.particlarExpense.mechanicName,
    mechanicNumber: this.particlarExpense.mechanicNumber,
    LRReceipt1: this.particlarExpense.LRReceipt1
    
  })
  if(this.userSessionData.user_role_id[0]!=1){
    this.editVehicleMaintenanceForm.get("supervisorname").reset({value:this.particlarExpense.SupervisorName,disabled:true});
  }
}
 getSuperVisorName(){
   this.superAdminService.getSuperVisorName()
   .subscribe((res: { status: any, message: any, data: any }) => {

     if (res.status == true) {
       var rawdata = res.data;
       this.superVisor = rawdata;
       //this.dtOptions.data = this.users;
     }
     else {
      if (res.status == 401) {
        this.toastr.error(res.message);
        window.sessionStorage.clear();
        this.router.navigateByUrl('superAuth/login');
        
      }else {
        this.toastr.error(res.message);
      }
     }

   }, error => {
   });
 }
 getCompanyName(){
     this.superAdminService.getClient()
       .subscribe((res: { status: any, message: any, data: any }) => {
 
         if (res.status == true) {
           var rawdata = res.data;
           this.company = rawdata;
           //this.dtOptions.data = this.users;
         }
         else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
         }
 
       }, error => {
       });
   
 }
 getVehicleType() {
   this.superAdminService.getVehicleType()
     .subscribe((res: { status: any, message: any, data: any }) => {

       if (res.status == true) {
         var rawdata = res.data;
         this.vehicletypes = rawdata;
         //this.dtOptions.data = this.users;
       }
       else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
       }

     }, error => {
     });

 }
 fileChangeEventattachReceipt1(fileInput: any) {

   // this.filesToUpload.push(fileInput.target.files[0]);
    this.filesToUpload=[];
    this.filesToUpload.push(fileInput.target.files[0] );
 }
 updateVehicleMaintenance() {

   if (this.editVehicleMaintenanceForm.valid) {
     this.removevalidatorcss("supervisornamectrl");
     this.removevalidatorcss("expenseCategoryctrl");
     this.removevalidatorcss("companyNameCtrl");
     this.removevalidatorcss("expenseDatectrl");
     this.removevalidatorcss("expenseAmountctrl");
     this.removevalidatorcss("expenseDescriptionctrl");
     this.removevalidatorcss("vehicleTypectrl");
     this.removevalidatorcss("vehicleNumberctrl");
     this.removevalidatorcss("otherChargesctrl");

     var form = {
      
       "supervisorId": this.editVehicleMaintenanceForm.get('supervisorname').value,
       "expenseSubCategoryId": this.editVehicleMaintenanceForm.get('expenseCategory').value,
       "companyId": this.editVehicleMaintenanceForm.get('companyname').value,
       "expenseDate": this.editVehicleMaintenanceForm.get('expenseDate').value,
       "expenseAmount": this.editVehicleMaintenanceForm.get('expenseAmount').value,
       "expenseDescription": this.editVehicleMaintenanceForm.get('expenseDescription').value,
       "vehicleType": this.editVehicleMaintenanceForm.get('vehicleType').value,
       "vehicleNumber": this.editVehicleMaintenanceForm.get('vehicleNumber').value,
       "otherCharges": this.editVehicleMaintenanceForm.get('otherCharges').value,
       "mechanicNumber": this.editVehicleMaintenanceForm.get('mechanicNumber').value,
       "mechanicName": this.editVehicleMaintenanceForm.get('mechanicName').value,
       "attachReceipt1": this.particlarExpense.attachReceipt1,
       "_id":this.paramId,
       
     }
     const formData = new FormData();
      const files: Array<File> = this.filesToUpload;
     
     for (let i = 0; i < this.filesToUpload.length; i++) {
        //formData.append("uploads[]", this.filesToUpload[i]);
        formData.append("uploads[]", this.filesToUpload[i]);
      }
     //formData.append("officeExpenceAttachment", this.filesToUpload[i]);
     formData.append('myFormData', JSON.stringify(form));
      this.spinner.show();
   this.superAdminService.updateVehicleMaintence(formData)
   .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
    this.spinner.hide();
       if (res.status == true) {
         //alert(res.message)
         this.toastr.success(res.message);
         this.router.navigate(['/superAdmin/vehicleMaintenence/vehicleMaintenenceList']);
         //this.addofficeExpenseForm.reset()
       }
       else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
         //this.addNewUser.reset()
       }
     
   }, error => {
    this.spinner.hide();
    this.toastr.error('internal server error');
   });
   }
   else {

     if (this.editVehicleMaintenanceForm.get('companyname').invalid) {
       this.addvalidatorcss("companyNameCtrl");
     } else {
       this.removevalidatorcss("companyNameCtrl");
     }

     if (this.editVehicleMaintenanceForm.get('supervisorname').invalid) {
       this.addvalidatorcss("supervisornamectrl");
     } else {
       this.removevalidatorcss("supervisornamectrl");
     }
   
     if (this.editVehicleMaintenanceForm.get('expenseCategory').invalid) {
       this.addvalidatorcss("expenseCategoryctrl");
     } else {
       this.removevalidatorcss("expenseCategoryctrl");
     }
     if (this.editVehicleMaintenanceForm.get('expenseDescription').invalid) {
       this.addvalidatorcss("expenseDescriptionctrl");
     } else {
       this.removevalidatorcss("expenseDescriptionctrl");
     }
     if (this.editVehicleMaintenanceForm.get('expenseDate').invalid) {
       this.addvalidatorcss("expenseDatectrl");
     } else {
       this.removevalidatorcss("expenseDatectrl");
     }
     if (this.editVehicleMaintenanceForm.get('expenseAmount').invalid) {
       this.addvalidatorcss("expenseAmountctrl");
     } else {
       this.removevalidatorcss("expenseAmountctrl");
     }
     if (this.editVehicleMaintenanceForm.get('vehicleType').invalid) {
      this.addvalidatorcss("vehicleTypectrl");
    } else {
      this.removevalidatorcss("vehicleTypectrl");
    }
    if (this.editVehicleMaintenanceForm.get('vehicleNumber').invalid) {
      this.addvalidatorcss("vehicleNumberctrl");
    } else {
      this.removevalidatorcss("vehicleNumberctrl");
    }
    if (this.editVehicleMaintenanceForm.get('mechanicNumber').invalid) {
      this.addvalidatorcss("mechanicNumberctrl");
    } else {
      this.removevalidatorcss("mechanicNumberctrl");
    }
    if (this.editVehicleMaintenanceForm.get('mechanicName').invalid) {
      this.addvalidatorcss("mechanicNamectrl");
    } else {
      this.removevalidatorcss("mechanicNamectrl");
    }
     if (this.editVehicleMaintenanceForm.get('LRReceipt1').invalid) {
       this.addvalidatorcss("LRReceipt1ctrl");
     } else {
       this.removevalidatorcss("LRReceipt1ctrl");
     }
     
   }
 }
 resetForm() {
  this.editVehicleMaintenanceForm.reset();
  this.supervisorname=undefined;
  this.initform();
  this.filesToUpload=[];
 }
 backTo(){
   window.history.back()
 }
 addvalidatorcss(id: string) {
   document.getElementById(id).classList.add('validatorcss');
 }
 removevalidatorcss(id: string) {
   document.getElementById(id).classList.remove('validatorcss');
 }
 preview(){
  if(this.filesToUpload.length){
    let file = this.filesToUpload[0];
    if(['image/jpeg','image/png','image/svg','image/svg+xml'].includes(file.type)){
      const dialogRef = this.dialog.open(ImagePreviewComponent, {
        width:'80%',
        height:'90vh',
        data:{file:file}
    });
    }
    else{
      let url =URL.createObjectURL(file);
      this.slipURL = this.sanitizer.bypassSecurityTrustUrl(url);
      setTimeout(()=>{
        this.showSlip.nativeElement.click();
      })
    }
  }
  else{
    let imagePath = this.particlarExpense.attachReceipt1;
    if(imagePath){
      let fileExtension=imagePath.split('.').slice(-1)[0];
      if(['jpeg','png','jpg','svg'].includes(fileExtension)){
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width:'80%',
          height:'90vh',
          data:{file:'/uploads/'+imagePath}
      });
      }
      else{
        this.slipURL = this.sanitizer.bypassSecurityTrustUrl(this.apiUrl+'/uploads/'+imagePath);
        setTimeout(()=>{
          this.showSlip.nativeElement.click();
        })
      }
    }
    else{
      this.toastr.error("No attachment");
    }
  }
}

}
