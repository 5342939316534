import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material';
import { SuperAdminService } from '../../../super-admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-expense-log',
  templateUrl: './add-expense-log.component.html',
  styleUrls: ['./add-expense-log.component.css']
})
export class AddExpenseLogComponent implements OnInit {

  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService) { }


  ngOnInit(): void {
  }
  backTo(){
    window.history.back()
  }

}
