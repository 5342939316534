import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { SuperAdminService } from 'src/app/super-admin.service';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-tracker',
  templateUrl: './view-tracker.component.html',
  styleUrls: ['./view-tracker.component.css']
})
export class ViewTrackerComponent implements OnInit {
  //dtOptions: DataTables.Settings = {};
  dtOptions: any = {};
  receipts: any[] = [];
  userSessionData: any;
  clientList: any;
  @ViewChild('paginator') paginator: MatPaginator;
  dataSource:  MatTableDataSource<any>;
  displayedColumns: any = ['manualLR_no', 'LR_no', 'vehicleType', 'vehicle_id', 'from', 'to', 'date', 'stateCode', 'fixAdhoc', 'startmeterReading', 'endmeterReading', 'tripStartDate', 'tripEndDate', 'noofTools', 'up_down_km', 'extrahour'];
  @ViewChild(MatSort) sort: MatSort;
  constructor(private superAdminService: SuperAdminService,private toastr: ToastrService,
    private router: Router , private spinner:NgxSpinnerService) { }


  ngOnInit(): void {
    this.spinner.show();
    this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
    this.clientList = this.userSessionData.clientList;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [],
      dom: 'Bfrtip',
      buttons: [
         'csv', 'excel'
        ]
    };
    this.superAdminService.getlrs()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data;
         // this.receipts = rawdata;
        // console.log("bhindwarg => ", rawdata);
        if(this.userSessionData.user_role_id[0]!=1){
          rawdata = rawdata.filter(item=>{
            return this.clientList.includes(item.receipt.clientName)
          })
        }
          this.receipts = rawdata.sort(function (a: any, b: any) {
            // console.log('<any>new Date(b.receipt.created_date) => ', <any>new Date(b.receipt.created_date));
            return <any>new Date(b.receipt.created_date) - <any>new Date(a.receipt.created_date);
          });
          this.dataSource = new MatTableDataSource(this.receipts);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          
          //console.log("bhindwarg => receipts", this.receipts);


        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            this.spinner.hide();
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
            this.spinner.hide();
          }
        }

      }, error => {
          this.toastr.error('Something went wrong! Please contact us');
          this.spinner.hide();
      });
  }
  filterData(value){
    this.dataSource.filter = value;
  }
  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'from':
          return compare(lower(a.from), lower(b.from), isAsc);
        case 'to':
          return compare(lower(a.to), lower(b.to), isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'tripEndDate':
          return compare(a.tripEndDate, b.tripEndDate, isAsc);
        case 'up_down_km':
          return compare(a.up_down_km, b.up_down_km, isAsc);
        case 'extrahour':
          return compare(a.extrahour, b.extrahour, isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string , b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value){
      console.log(value?value.toLowerCase():value);
      return value?value.toLowerCase():value;
    }
  }
  backTo() {
    window.history.back()
  }

}
