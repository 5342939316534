import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutSuperAdminComponent } from './superAdmin/main-layout/main-layout.component';
import { ActivateGuard } from './activate.guard';
import { LoginComponent } from './superAdmin/auth/login/login.component';
import { OfficeExpenseComponent } from './superAdmin/manage-expense-log/office-expense/office-expense.component';
import { VehicleMaintenanceComponent } from './superAdmin/manage-expense-log/vehicle-maintenance/vehicle-maintenance.component';
import { OurownVehicleExpenseComponent } from './superAdmin/manage-expense-log/ourown-vehicle-expense/ourown-vehicle-expense.component';
import { OfficeExpanceListComponent } from './superAdmin/manage-expense-log/office-expance-list/office-expance-list.component';
import { VehicleMaintainanceListComponent } from './superAdmin/manage-expense-log/vehicle-maintainance-list/vehicle-maintainance-list.component';
import { ExpanceLayoutComponent } from './superAdmin/manage-expense-log/expance-layout/expance-layout.component';
import { ProfileComponent } from './superAdmin/profile/profile.component';
const routes: Routes = [

  {
    path: 'superAdmin',
    component: MainLayoutSuperAdminComponent,
    //canActivate: [ActivateGuard],
    children: [
      {
        path: 'mydashboard',
        loadChildren: () => import('./superAdmin/mydashboard/mydashboard.module').then(m => m.MydashboardModule)
      },
       {
        path: 'profile', 
        loadChildren: () => import('./superAdmin/profile/profile.module').then(m => m.ProfileModule)
      },
     
      {
        path: 'usermanagement',
        loadChildren: () => import('./superAdmin/user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'lrmanagement',
        loadChildren: () => import('./superAdmin/LR-management/LR-management.module').then(m => m.LrManagementModule)
      },
      {
        path: 'manageExpense',
        loadChildren: () => import('./superAdmin/manage-expense/manage-expense.module').then(m => m.ManageExpenseModule)
      },
      {
        path: 'manageVehicle',
        loadChildren: () => import('./superAdmin/manage-vehicle/manage-vehicle.module').then(m => m.ManageVehicleModule)
      },
      {
        path: 'manageDriver',
        loadChildren: () => import('./superAdmin/manage-driver/manage-driver.module').then(m => m.ManageDriverModule)
      },

      {
        path: 'manageClient',
        loadChildren: () => import('./superAdmin/manage-client/manage-client.module').then(m => m.ManageClientModule)
      },
      {
        path: 'officeExpense', component:ExpanceLayoutComponent,
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      },
      // {
      //   path: 'officeExpense/AddOfficeExpense', component:OfficeExpenseComponent,
      //   loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      // },
      {
        path: 'vehicleMaintenence', component:ExpanceLayoutComponent,
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      },
      {
        path:'ownVehicleExpence', component:ExpanceLayoutComponent,
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      },
      {
        path: 'vehicleMaintenence/AddvehicleMaintenence', component:VehicleMaintenanceComponent,
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)

      },
      {
        path: 'ourownVehicleExpense', component:OurownVehicleExpenseComponent,
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)

      },
      {
        path: 'manageExpenseLog',
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      },
     
    ]
  },
  {
    path: 'superAuth',
    loadChildren: () => import('./superAdmin/auth/auth.module').then(m => m.AuthModule)
  },

  { path: '', pathMatch: 'full', redirectTo: '/superAuth/login' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
