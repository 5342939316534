<div class="container-fluid">

    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Manage LR </h1>
                <div>
                    <button class="btn btn-dark btn-sm " mat-raised-button
                    routerLink="/superAdmin/lrmanagement/view-invoice">View
                    Invoice </button>
                    <button class="btn btn-dark btn-sm ms-2" mat-raised-button
                        routerLink="/superAdmin/lrmanagement/view-tracker">View
                        Tracker</button>
                    <button class="btn btn-dark btn-sm ms-2" matTooltip="Add LR" matTooltipPosition="left" mat-raised-button
                        routerLink="/superAdmin/lrmanagement/add-lr"><i class="fa-solid fa-plus"></i></button>
                </div>
            </div>


        </div>

    </div>
    <div class="contentbox mt-3">
    <div class="row customepadding  ">

        <div class="col-md-12">

            <div class="table-responsive">

                <!-- <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='receipts.length > 0'>
                    <thead>
                        <tr>

                            <td>LR No.</td>
                            <td>Vehicle NO.</td>
                            <td>Company Name</td>
                            <td>Consignee Name</td>

                            <td>Consignee Address</td>
                            <td>Consignor Name</td>
                            <td>Consignor Address</td>

                            <td>Date</td>
                            <td>From</td>
                            <td>TO</td>

                            <td class="text-center">Action</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="!isArray">
                        <tr *ngFor="let receipt of receipts">


                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.LR_no}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.vehicle_id}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.clientName}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.consignee_name}}</td>

                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.consignee_address}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.consignor_name}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.consignor_address}}</td>


                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.date | date:'yyyy-MM-dd'}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.from}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]">
                                {{receipt.to}}</td>

                            <td>
                                <div class="d-flex justify-content-center align-items-center">
                                    <button [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"
                                        class="btn btn-block p-0 shadow-none"><i matTooltip="View"
                                            class="fas fa-print"></i></button>
                                    <button [routerLink]="['/superAdmin/lrmanagement/edit-lr', receipt._id]"
                                        class="btn btn-block p-0 px-3  shadow-none"><i matTooltip="Edit"
                                            class="fas fa-pen"></i></button>
                                    <button [routerLink]="['/superAdmin/lrmanagement/detail-lr', receipt._id]"
                                        class="btn btn-block p-0  shadow-none">
                                        <i matTooltip="Detail" class="fas fa-eye"></i>
                                    </button>
                                </div>
                            </td>


                        </tr>


                    </tbody>

                    <tbody *ngIf="isArray">
                        <tr *ngFor="let receipt of receipts">


                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]">
                                {{receipt?.LR_no}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]">
                                {{receipt?.vehicle_id}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]">
                                {{receipt?.consignee_name}}</td>

                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]">
                                {{receipt?.consignee_address}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]">
                                {{receipt?.consignor_name}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]">
                                {{receipt?.consignor_address}}</td>


                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]">
                                {{receipt?.date | date:'yyyy-MM-dd'}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]">
                                {{receipt?.from}}</td>
                            <td [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]">
                                {{receipt?.to}}</td>

                            <td>
                                <div class="d-flex justify-content-center align-items-center">
                                    <button [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt?._id]"
                                        class="btn btn-block"><i matTooltip="View" class="fas fa-print"></i></button>
                                    <button [routerLink]="['/superAdmin/lrmanagement/edit-lr', receipt?._id]"
                                        class="btn btn-block"><i matTooltip="Edit" class="fas fa-pen"></i></button>
                                    <button [routerLink]="['/superAdmin/lrmanagement/detail-lr', receipt?._id]"
                                        class="btn btn-block">
                                        <i matTooltip="Detail" class="fas fa-eye"></i>
                                    </button>
                                </div>
                            </td>


                        </tr>


                    </tbody>
                </table> -->
                <input class='search-box' #data placeholder="search"type="text" (keyup)="filterData(data.value)"/>
                <table mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc" (matSortChange)="sortData($event)" class="mat-elevation-z8 row-border hover tablelist" >
                        
                    <ng-container matColumnDef="LR_no">
                        <th mat-header-cell *matHeaderCellDef>System LR No.</th>
                        <td mat-cell *matCellDef="let receipt; let i = index" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.LR_no}} </td>
                      </ng-container>
                      
                    <ng-container matColumnDef="manualLR_no">
                        <th mat-header-cell *matHeaderCellDef>Manual LR No.</th>
                        <td mat-cell *matCellDef="let receipt; let i = index" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.manualLR_no}} </td>
                      </ng-container>

                    <ng-container matColumnDef="clientName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
                    <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.clientName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="vehicle_id">
                        <th mat-header-cell *matHeaderCellDef>Vehicle No.</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.vehicle_id}} </td>
                      </ng-container>

                      <ng-container matColumnDef="consignee_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consignee Name</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.consignee_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="consignor_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consignor Name</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.consignor_name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.date | date:'yyyy-MM-dd'}} </td>
                      </ng-container>

                      <ng-container matColumnDef="from">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.from}} </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="to">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"> {{receipt.to}} </td>
                      </ng-container>

                      <ng-container matColumnDef="vehicle_rate">
                        <th mat-header-cell *matHeaderCellDef>Vehicle Rate</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]" class="text-center"> {{receipt.vehicleRate}} </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="advance">
                        <th mat-header-cell *matHeaderCellDef>Advance</th>
                        <td mat-cell *matCellDef="let receipt" [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]" class="text-center"> {{receipt.advance}} </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="action" >
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                        <td mat-cell *matCellDef="let receipt">
                            <div class="d-flex justify-content-center align-items-center">
                                <button [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"
                                    class="btn btn-block p-0 shadow-none"><i matTooltip="View"
                                        class="fas fa-print"></i></button>
                                <button [routerLink]="['/superAdmin/lrmanagement/edit-lr', receipt._id]"
                                    class="btn btn-block p-0 px-3  shadow-none"><i matTooltip="Edit"
                                        class="fas fa-pen"></i></button>
                                <button [routerLink]="['/superAdmin/lrmanagement/detail-lr', receipt._id]"
                                    class="btn btn-block p-0  shadow-none">
                                    <i matTooltip="Detail" class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    
                </table>

            </div>
            <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </div>
    </div>
    </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>