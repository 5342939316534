<div class="container-fluid paddingright">

    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Invoice</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item active" aria-current="page">Manage LR</li>

                    <li class="breadcrumb-item active" aria-current="page">View Invoice</li>
                </ol>
            </nav>

        </div>

    </div>
    <div class="contentbox w-90vw mt-3">
        <form [formGroup]="filterform">
            <div class="row">
                <div class="col-md-4">

                    <div class="form-group accountsinp  mb-3">
                        <label for="">Company Name</label>
                        <select id="clientNamectrl" formControlName="clientName" placeholder="Select Client"
                            class="form-control shadow-none" (change)="onFocusGetLr($event)">
                            <option value="">--Select Company Name--</option>
                            <option *ngFor="let c of clientList" value="{{c._id}}">{{c.clientName}}
                            </option>

                        </select>
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">LR No.</label>
                        <select id="LR_noctrl" formControlName="LR_no" placeholder="Select LR"
                            class="form-control shadow-none">
                            <option value="" disabled>--Select LR No.--</option>
                            <option *ngFor="let r of lrNumber" value="{{r.item_text}}">{{r.item_text}}
                            </option>

                        </select>
                    </div>
                </div> -->
                <!-- =============== multi select lr number =========================== -->
                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4"> 
                    <!-- <form [formGroup]="form"> -->
                    <label for="">System LR No.<sup class="text-danger">*</sup></label>
                    <div class="form-group accountsinp  mb-3">
                        <ng-multiselect-dropdown  formControlName="LR_no" [placeholder]="'Select LR No.'"
                            [settings]="dropdownSettings" [data]="lrNumber" (onSelect)="onItemSelect($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>

                     <!-- =============== multi select lr number end =========================== -->
                    <div class="col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Invoice Type</label>
                            <select id="invoiceTypectrl" formControlName="invoiceType" placeholder="Select Invoice Type"
                                class="form-control shadow-none">
                                <option value="">--Select Invoice Type--</option>
                                <option *ngFor="let s of invoiceType" value="{{s.invoiceTypeName}}">
                                    {{s.invoiceTypeName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xl-12 text-end">
                        <button class="btn btn-dark ms-2" type="submit" mat-raised-button (click)="generateInvoice()">
                            Invoice Generate</button>
                    </div>
                </div>
        </form>
    </div>
    
    <div class="contentbox w-90vw mt-3">
        <div class="row">
            <div class="col-md-12">

                <div class="table-responsive ">
                    <!-- <table id="example" datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='invoiceList.length > 0'>
                        <thead>
                            <tr>

                                <td>Client Name</td>
                                <td>Date</td>
                                <td class="text-center">Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let client of clientName">
                                <td>
                                    {{client.clientName}}</td>
                                <td>
                                    {{client.date | date:'yyyy-MM-dd'}}</td>
                                <td>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button [routerLink]="['/superAdmin/lrmanagement/invoice', client._id]"
                                        class="btn btn-block"><i matTooltip="View" class="fa-solid fa-eye"></i></button>
                                        <button (click)="deleteInvoice(client._id)"
                                        class="btn btn-block"><i matTooltip="Delete" class="fa fa-trash"></i></button>
                                    </div>
                                </td>
                            
                            </tr>


                        </tbody>
                    </table> -->
                    <input class='search-box' #data placeholder="search"type="text" (keyup)="filterData(data.value)"/>
                    <table mat-table [dataSource]="dataSource" matSort matSortActive="created_date" matSortDirection="desc" (matSortChange)="sortData($event)" class="mat-elevation-z8 row-border hover tablelist" >
                        
                        <ng-container matColumnDef="sno">
                            <th mat-header-cell *matHeaderCellDef > S.no </th>
                            <td mat-cell *matCellDef="let client; let i = index"> {{ (paginator.pageIndex * paginator.pageSize) + (i + 1) }} </td>
                          </ng-container>

                        <ng-container matColumnDef="clientName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
                            <td mat-cell *matCellDef="let client"> {{client.clientName}} </td>
                          </ng-container>
                        
                          <ng-container matColumnDef="created_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                            <td mat-cell *matCellDef="let client"> {{client.created_date | date:'yyyy-MM-dd'}} </td>
                          </ng-container>
                        
                          <ng-container matColumnDef="action" >
                            <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                            <td mat-cell *matCellDef="let client">
                                <div class="d-flex justify-content-center align-items-center">
                                    <button [routerLink]="['/superAdmin/lrmanagement/invoice', client._id]"
                                    class="btn btn-block"><i matTooltip="View" class="fa-solid fa-eye"></i></button>
                                    <button 
                                    (click)="getParticularInvoice(client._id)"
                                     
                                        class="btn btn-block"><i matTooltip="print" class="fas fa-print" ></i></button>
                                    <button #printBtn [useExistingCss]="true" printSectionId="print-section" [style.display]="'none'" ngxPrint></button>
                                    <button 
                                    class="btn btn-block" (click)="sendInvoice('print-section',client._id)"><i matTooltip="send" class="fa fa-paper-plane"></i></button>
                                    <button (click)="deleteInvoice(client._id)"
                                    class="btn btn-block"><i matTooltip="Delete" class="fa fa-trash"></i></button>
                                </div>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        
                    </table>
                    <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>
                    <div class="NewInvoice table-responsive" id="print-section" [style.display]="'none'">
                        <table class="newInvoiceBox">
                            <tr>
                                <td class="py-3">
                                    <table class="invoiceHead">
                                        <tr>
                                            <td>Transporter Name </td>
                                            <td>: Radha swami transport service</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>Location</td>
                                            <td>: Shahdara</td>
                                        </tr>
                                        <tr>
                                            <td>Type of Vehicle </td>
                                            <td>: ECCO</td>
                                        </tr>
                                        <tr>
                                            <td>Vehicle No</td>
                                            <td>: DL1V7320</td>
                                        </tr> -->
                                    </table>
                                </td>
                                <td class="text-end py-3">
                                    <img src="../../../../assets/images/invoiceLogo.png" width="200" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <table class="invoiceDetails" *ngIf='users.length > 0'>
                                        <thead>
                                            <tr>
                                                <td>Date</td>
                                                <td>System LR No.</td>
                                                <td>Manual LR No.</td>
                                                <td>VEHICLE NO:</td>
                                                <td>From</td>
                                                <td>TO</td>
                                                <td>START READING</td>
                                                <td>END.READING</td>
                                                <td>KMR.</td>
                                                <td>EXTRA HRS</td>
                                                <td>Toll & Parking</td>
                                                <td>AMOUNT</td>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let user of users; let j=index">
                                            <tr  *ngFor="let j of user.LR_no;let i=index">
                                                <td>{{user.date[i] | date }}</td>
                                                <td>{{user.LR_no[i] }}</td>
                                                <td>{{user.manualLR_no[i] }}</td>
                                                <td>{{user.vehicle_id[i] }}</td>
                                                <td>{{user.from[i] }}</td>
                                                <td>{{user.to[i] }}</td>
                                                <td>{{user.startmeterReading[i] }}</td>
                                                <td>{{user.endmeterReading[i] }}</td>
                                                <td>{{user.up_down_km[i] }}</td>
                                                <td>{{user.extrahour[i] }} </td>
                                                <td>{{user.tooltaxPaid[i] }}</td>
                                                <td>{{user.balancePaid[i] }}</td>
                                            </tr>
                                           
                                            <tr>
                                                <td class="footer">TOTAL</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">{{this.kmtot | number:'1.2-2'}}</td>
                                                <td class="footer">{{this.extrahour | number:'1.2-2'}}</td>
                                                <td class="footer">{{this.tooltaxPaidtot | number:'1.2-2'}}</td>
                                                <td class="footer">{{this.invtot | number:'1.2-2'}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="8"></td>
                                                <td colspan="3" class="p-0 ">
                                                    <table class="extraText">
                                                        <tr>
                                                            <td>Total KM={{this.kmtot}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Extra Hours={{this.extrahour}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Toll & Parking={{this.tooltaxPaidtot}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total={{this.invtot | number:'1.2-2'}}</td>
                                                        </tr>
                                                    </table>
                                                </td>
                    
                                            </tr>
                                            <tr>
                                                <td colspan="11"><b>Rupees-One Lakh forty two thousand five hundred  twenty seven only.
                                                </b></td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                    <table class="invoiceDetails" hidden>
                                        <thead>
                                            <tr>
                                                <td>Date</td>
                                                <td>Opening Reading </td>
                                                <td>Closing Reading</td>
                                                <td>Total KM</td>
                                                <td>Start Time</td>
                                                <td>End Time </td>
                                                <td>Total Hours</td>
                                                <td>Destination/Route</td>
                                                <td>Toll </td>
                                                <td> Parking</td>
                                                <td>Value of Total Invoices</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01.11.2019</td>
                                                <td>165690</td>
                                                <td>165732</td>
                                                <td>42</td>
                                                <td>06.00AM</td>
                                                <td>12.00PM </td>
                                                <td>&nbsp;</td>
                                                <td>CANNAUGHT PLACE</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>12573</td>
                                            </tr>
                                            <tr>
                                                <td>01.11.2019</td>
                                                <td>165690</td>
                                                <td>165732</td>
                                                <td>42</td>
                                                <td>06.00AM</td>
                                                <td>12.00PM </td>
                                                <td>6</td>
                                                <td>CANNAUGHT PLACE</td>
                                                <td>230</td>
                                                <td>&nbsp;</td>
                                                <td>12573</td>
                                            </tr>
                                            <tr>
                                                <td>01.11.2019</td>
                                                <td>165690</td>
                                                <td>165732</td>
                                                <td>42</td>
                                                <td>06.00AM</td>
                                                <td>12.00PM </td>
                                                <td>&nbsp;</td>
                                                <td>CANNAUGHT PLACE</td>
                                                <td>&nbsp;</td>
                                                <td>40</td>
                                                <td>12573</td>
                                            </tr>
                                            <tr>
                                                <td class="footer">TOTAL</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">3997</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">65</td>
                                                <td class="footer">&nbsp;</td>
                                                <td class="footer">2370</td>
                                                <td class="footer">1700</td>
                                                <td class="footer">2182563</td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <p>We hereby confirmed that all the LR copies duly  acknowledged by the customers have been received and above details are found correct.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <table class="signature">
                                        <tr>
                                            <td>
                                                <p>Prepaired By</p>
                                                <p>Name</p>
                                                <p>Designation</p>
                                            </td>
                                            <td>
                                                <p>Verified By</p>
                                                <p>Name</p>
                                                <p>Designation</p>
                                            </td>
                                            <td>
                                                <p>Approved By</p>
                                                <p>Name</p>
                                                <p>Designation</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>