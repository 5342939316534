import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';
import { Observable, empty } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { DomSanitizer } from '@angular/platform-browser';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-lr',
  templateUrl: './add-lr.component.html',
  styleUrls: ['./add-lr.component.css']
})
export class AddLrComponent implements OnInit {
  vehicles = [];
  vehiclesNumber = [];
  vehicleType;
  InvoiceDetails: MatTableDataSource<any> = new MatTableDataSource([]);
  vehicletypes = [];
  vehicleTypesNames = [];
  filesToUpload: Array<File> = [];
  Tollfiles: Array<File> = [];
  TollfilesString = [];
  loadingSlips: Array<File> = [];
  loadingSlipsString = [];
  clientList = [];
  actualbalance = 0;
  updownkm = 0;
  totalhour=0;
  DriverList=[]
  // DriverNumber;
  DriverNames = [];
  DriverNumbers = [];
  displayedColumns=['noOfArticle','invoiceNo','customerName','invoiceValue','weight','pod'];
  options: string[] = ['One', 'Two', 'Three'];
  filteredVehicleTypes: Observable<string[]>;
  filteredVehicleNo: Observable<string[]>;
  filteredDriverName: Observable<string[]>;
  filteredDriverNumber: Observable<string[]>;
  //Tollfiles:string  []  =  [];
  addNewLR = new FormGroup({
    supervisorname: new FormControl(null,  Validators.compose([Validators.required])),
    clientList: new FormControl('',  Validators.compose([Validators.required])),
    manualLR_no: new FormControl(''),
    ConsigneeName: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    ConsigneeAddress: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    ConsignorName: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    ConsignorAddress: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    VehicleNo: new FormControl(''),
    vehicleType: new FormControl(''),
    from: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    to: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    GSTIN: new FormControl('',Validators.compose([Validators.required,Validators.maxLength(15)])),
    POD: new FormControl('',),
    date: new FormControl('',Validators.required),
    RoadPermitNo: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    StateCode: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    DLY: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    DeclaredValue: new FormControl('',Validators.required),
    fixAdhoc: new FormControl('',Validators.required),
    quantity: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    transportorName: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    transportorMobile: new FormControl('',Validators.compose([Validators.required, Validators.pattern(/^\d{10}$/)])),
    driverName: new FormControl(''),
    driverMobile: new FormControl(''),
    customerName:new FormControl('',Validators.required),
    //tollAttachment: new FormControl(''),
    loadingSlipAttchment: new FormControl(''),
    Frieght: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    InvoiceNo: new FormControl('',  Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    InvoiceValue: new FormControl('',  Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    Weight: new FormControl('',  Validators.compose([Validators.required, Validators.pattern(/^\d+[.]{1}\d+$|^\d+$/)])),
    NoofArticle: new FormControl('',  Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    startmeterReading: new FormControl('',  Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    endmeterReading: new FormControl('',  Validators.compose([ Validators.pattern("^[0-9]*$")])),
    tripSDate: new FormControl('',Validators.required),
    tripEDate: new FormControl(''),
    outtime: new FormControl('',Validators.required),
    INtime: new FormControl(''),
    noofTools: new FormControl('', Validators.pattern("^[0-9]*$")),
    tooltaxPaid: new FormControl('', Validators.pattern("^[0-9]*$")),
    labourPaid: new FormControl('', Validators.pattern("^[0-9]*$")),
    otherPayment: new FormControl('', Validators.pattern("^[0-9]*$")),
    updownkm: new FormControl(''),
    totalhour: new FormControl(''),
    extrahour: new FormControl('', Validators.pattern("^[0-9]*$")),
    detention: new FormControl('', Validators.pattern("^[0-9]*$")),
    advance: new FormControl('',Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    balance: new FormControl(''),
    balancePaid: new FormControl('',Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    vehicleRate: new FormControl('',Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    
    //status: new FormControl('')
  })
  docURL:any;
  podURL:any;
  podParticularURL: any;
  @ViewChild('showDoc') showDoc: ElementRef;
  @ViewChild('showPOD') showPOD: ElementRef;
  @ViewChild('showParticularPOD') showParticularPOD: ElementRef;
  superVisor: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private toastr: ToastrService, private superAdminService: SuperAdminService, private spinner: NgxSpinnerService,  public dialog: MatDialog, private sanitizer:DomSanitizer) {
    this.activatedRoute.params.subscribe(param => {
      // this.paramId = param.id;
      this.getVehicleDetails();
    })
  }
  count_user_click = 0;
  decValue = 0;
  submitLRClicked = false;
  get ff() {
    return this.addNewLR.controls
  }
  ngOnInit(): void {

    this.getVehicleType();
    this.getClientList();
    this.getDriverNames();
    this.getSuperVisorName();
    
  }
  getDriverNames() {
    this.superAdminService.getDrivers()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.DriverList = rawdata;
          this.DriverList.forEach((item)=>{
            if(item.driver_name){
              this.DriverNames.push(item.driver_name)
            }
            if(item.contactNumber){
              this.DriverNumbers.push(item.contactNumber)
            }
          })
          this.filteredDriverName = this.ff.driverName.valueChanges.pipe(
            startWith(''),
            map(value => this._filterDriverName(value || '')),
          );
          this.filteredDriverNumber = this.ff.driverMobile.valueChanges.pipe(
            startWith(''),
            map(value => this._filterDriverNumber(value || '')),
          );
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.success(res.message);
          }
        }
      }, error => {
      });

  }
  private _filterDriverName(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.DriverNames.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterDriverNumber(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.DriverNumbers.filter(option => option.toLowerCase().includes(filterValue));
  }
  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.clientList = rawdata;
          let userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
          let userroleid = userSessionData.user_role_id[0];
          if (userroleid != 1) {
            let clients = userSessionData.clientList;
            this.clientList = clients.map(id => {
            return this.clientList.filter(item=>item._id==id)[0]
          });
          }
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.success(res.message);
          }
        }
      }, error => {
      });

  }
  getVehicleType() {
    this.superAdminService.getVehicleType()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.vehicletypes = rawdata;
          this.vehicletypes.forEach((item)=>{
            this.vehicleTypesNames.push(item.vehicletype_name)
          })
          this.filteredVehicleTypes = this.ff.vehicleType.valueChanges.pipe(
            startWith(''),
            map(value => this._filterVehicleType(value || '')),
          );
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });

  }
  private _filterVehicleType(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vehicleTypesNames.filter(option => option.toLowerCase().includes(filterValue));
  }
  getVehicleDetails() {
    this.superAdminService.getvehicles().subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.vehicles = res.data;
        this.vehicles.forEach((item)=>{
          this.vehiclesNumber.push(item.vehicle_no)
        })
        this.filteredVehicleNo = this.ff.VehicleNo.valueChanges.pipe(
          startWith(''),
          map(value => this._filterVehicleNo(value || '')),
        );
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.success(res.message);
        }
      }

    }, error => {
    });
  }
  private _filterVehicleNo(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vehiclesNumber.filter(option => option.toLowerCase().includes(filterValue));
  }
  fileChangeEventtollAttachment(fileInput: any) {
    for (var i = 0; i < fileInput.target.files.length; i++) {
      this.Tollfiles.push(fileInput.target.files[i]);
    }

    // this.filesToUpload.push(fileInput.target.files[0] );

  }
  fileChangeEventloadingSlipAttchment(fileInput: any) {
    this.loadingSlips = [];
    for (var i = 0; i < fileInput.target.files.length; i++) {
      this.loadingSlips.push(fileInput.target.files[i]);
    }

  }

  addTollFiles() {
    const formData = new FormData();

    for (let i = 0; i < this.Tollfiles.length; i++) {
      formData.append("Tollfiles[]", this.Tollfiles[i]);
    }

    this.superAdminService.addTollFiles(formData)
      .subscribe((res: { rawdata: any, status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          //alert(res.message)
          this.toastr.success(res.message);
          this.TollfilesString = res.rawdata;
          //this.router.navigate(['/superAdmin/lrmanagement']);
          //this.addNewLR.reset()
        }
        else {
          // alert(res.message)
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.success(res.message);
          }
          //this.addNewLR.reset()
        }

      }, error => {
      });

  }

  addloadingSlips() {
    this.spinner.show();
    const formData = new FormData();
    for (let i = 0; i < this.loadingSlips.length; i++) {
      formData.append("loadingSlips[]", this.loadingSlips[i]);
    }
    this.superAdminService.addloadingSlips(formData)
      .subscribe((res: { rawdata: any, status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          this.toastr.success(res.message);
          this.loadingSlipsString = res.rawdata;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }
        this.spinner.hide();

      }, error => {
        this.spinner.hide();
      });


  }
  fileChangeEventAttachment(fileInput: any) {
    this.filesToUpload.push(fileInput.target.files[0]);
  }
  onFocusOutEvent(event: any) {
    // this.actualbalance = parseInt(document.getElementById("vehicleRatectrl")['value']) -
    //   parseInt(document.getElementById("advancectrl")['value']) -
    //   parseInt(event.target.value);
    this.actualbalance = parseInt(this.addNewLR.get('vehicleRate').value) -
      parseInt(this.addNewLR.get('advance').value) -
      parseInt(event.target.value);
    this.addNewLR.patchValue({
      balance: this.actualbalance
    });

  }
  onFocusOutendmeterReading(event: any) {
    this.updownkm = parseInt(event.target.value) -
                    parseInt(this.addNewLR.get('startmeterReading').value) ;
    this.addNewLR.patchValue({
      updownkm: this.updownkm
    });


  }
  getVehicleTypeFromNumber(event:any){
   
    // this.vehicleType= this.vehicles.map{return this.vehicles.vehicle_no == event.target.value} 
    for(let i =0; i<this.vehicles.length; i++){
      if(this.vehicles[i].vehicle_no == event.target.value){
        this.vehicleType = this.vehicles[i].type;
        break;
      }
    }
    this.addNewLR.patchValue({
      vehicleType:  this.vehicleType
      
    });
  }
  // onFocusDriverName(event : any){
  //     for(let i=0; i<this.DriverList.length; i++){
  //       if(this.DriverList[i].driver_name== event.target.value){
  //              this.DriverNumber = this.DriverList[i].contactNumber
  //       }
  //     }
  //     this.addNewLR.patchValue({
  //       driverMobile:  this.DriverNumber
  //     });
  //   this.onKeyDriverName();
  // }
  onFocusOuttripEDate(event: any) {

    
    let outtime = this.addNewLR.get('outtime').value;
    let INtime = this.addNewLR.get('INtime').value;
    let sdate = this.addNewLR.get('tripSDate').value;
    let edate = this.addNewLR.get('tripEDate').value;
    let sd1=sdate+' '+outtime+':00';
    let date1 = new Date(sd1);
    let sd2=edate+' '+INtime+':00';
    let date2 = new Date(sd2);
    var  diff = (date2.getTime() - date1.getTime())/1000;
    diff /= (60 * 60);
    let ht=Math.abs(Math.round(diff));
    
     this.addNewLR.patchValue({
      totalhour: ht
     });


  }
  addInvoiceDetail() {
    //  var f={
    //   "InvoiceNo" : this.addNewLR.get('InvoiceNo').value,
    //   "InvoiceValue" : this.addNewLR.get('InvoiceValue').value,
    //   "NoofArticle" : this.addNewLR.get('NoofArticle').value,
    //   "Weight" : this.addNewLR.get('Weight').value,
    //   "Frieght" : this.addNewLR.get('Frieght').value,
    //  }
    // var i=1;
    var f = {

      "id": "inv" + this.count_user_click,
      "InvoiceNo": document.getElementById("InvoiceNoctrl")['value'],
      "InvoiceValue": document.getElementById("InvoiceValuectrl")['value'],
      "NoofArticle": document.getElementById("NoofArticlectrl")['value'],
      "Weight": document.getElementById("Weightctrl")['value'],
      //"Frieght": document.getElementById("Frieghtctrl")['value'],
      "customerName": document.getElementById("customerNamectrl")['value'],
       "POD": document.getElementById("PODctrl")['value'],

    }
    if ((document.getElementById("InvoiceNoctrl")['value'] != '')
      && (document.getElementById("InvoiceValuectrl")['value'] != '' )
      && (document.getElementById("NoofArticlectrl")['value'] != '')
      && (document.getElementById("Weightctrl")['value'] != '')
      // && (document.getElementById("Frieghtctrl")['value'] != '')
      && (document.getElementById("customerNamectrl")['value'] != '')
      // && (document.getElementById("PODctrl")['value'] != '')
    ) {
      this.count_user_click += 1;
      let temp = this.InvoiceDetails.filteredData;
      temp.push(f);
      this.InvoiceDetails= new MatTableDataSource(temp);


      this.decValue += parseInt(document.getElementById("InvoiceValuectrl")['value']);
      this.addNewLR.patchValue({
        DeclaredValue: this.decValue

      });

      document.getElementById("InvoiceNoctrl")['value'] = '';
      document.getElementById("InvoiceValuectrl")['value'] = '';
      document.getElementById("NoofArticlectrl")['value'] = '';
      document.getElementById("Weightctrl")['value'] = '';
      //document.getElementById("Frieghtctrl")['value'] = '';
      document.getElementById("customerNamectrl")['value'] = '';
      document.getElementById("PODctrl")['value'] = '';
      this.removevalidatorcss("InvoiceNoctrl");
      this.removevalidatorcss("InvoiceValuectrl");
      this.removevalidatorcss("NoofArticlectrl");
      this.removevalidatorcss("Weightctrl");
      //this.removevalidatorcss("Frieghtctrl");
      this.removevalidatorcss("customerNamectrl");
      this.removevalidatorcss("PODctrl");
    }
    else {
      if (this.addNewLR.get('InvoiceNo').invalid) {
        this.addvalidatorcss("InvoiceNoctrl");
      } else {
        this.removevalidatorcss("InvoiceNoctrl");
      }
      if (this.addNewLR.get('InvoiceValue').invalid) {
        this.addvalidatorcss("InvoiceValuectrl");
      } else {
        this.removevalidatorcss("InvoiceValuectrl");
      }
      if (this.addNewLR.get('NoofArticle').invalid) {
        this.addvalidatorcss("NoofArticlectrl");
      } else {
        this.removevalidatorcss("NoofArticlectrl");
      }
      if (this.addNewLR.get('Weight').invalid) {
        this.addvalidatorcss("Weightctrl");
      } else {
        this.removevalidatorcss("Weightctrl");
      }
      if (this.addNewLR.get('customerName').invalid) {
        this.addvalidatorcss("customerNamectrl");
      } else {
        this.removevalidatorcss("customerNamectrl");
      }
    }
    //this.addNewLR.reset();
    // this.InvoiceDetails = 
    // {
    //   "InvoiceNo" : this.addNewLR.get('InvoiceNo').value,
    // }
  }
  getSuperVisorName(){
    this.superAdminService.getSuperVisorName()
    .subscribe((res: { status: any, message: any, data: any }) => {

      if (res.status == true) {
        var rawdata = res.data;
        this.superVisor = rawdata;
        //this.dtOptions.data = this.users;
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
      }
    }, error => {
    });
  }

  addNewLRSubmit() {
    this.spinner.show();
    this.submitLRClicked = true;
    if (this.addNewLR.valid) {
      // Basic Details 
      this.removevalidatorcss("supervisornamectrl");
      this.removevalidatorcss("clientListctrl");
      this.removevalidatorcss("ConsigneeNamectrl");
      this.removevalidatorcss("ConsigneeAddressctrl");
      this.removevalidatorcss("ConsignorAddressctrl");
      this.removevalidatorcss("ConsignorNamectrl");
      this.removevalidatorcss("VehicleNoctrl");
      this.removevalidatorcss("vehicleTypectrl");
      this.removevalidatorcss("fromctrl");
      this.removevalidatorcss("toctrl");
      this.removevalidatorcss("GSTINctrl");
      this.removevalidatorcss("datectrl");
      this.removevalidatorcss("fixAdhocctrl");
      this.removevalidatorcss("balancectrl");
      this.removevalidatorcss("RoadPermitNoctrl");
      this.removevalidatorcss("StateCodectrl");
      this.removevalidatorcss("DLYctrl");
      this.removevalidatorcss("DeclaredValuectrl");
      this.removevalidatorcss("transportorNamectrl");
      this.removevalidatorcss("transportorMobilectrl");
      this.removevalidatorcss("driverNamectrl");
      this.removevalidatorcss("driverMobilectrl");
      //this.removevalidatorcss("tollAttachmentctrl");
      this.removevalidatorcss("loadingSlipAttchmentctrl");
      //this.removevalidatorcss("statusctrl");
      this.removevalidatorcss("Frieghtctrl");


      // Information For office use only
      this.removevalidatorcss("startmeterReadingctrl");
      this.removevalidatorcss("endmeterReadingctrl");
      this.removevalidatorcss("tripSDatectrl");
      this.removevalidatorcss("tripEDatectrl");
      this.removevalidatorcss("outtimectrl");
      this.removevalidatorcss("INtimectrl");
      this.removevalidatorcss("noofToolsctrl");
      this.removevalidatorcss("tooltaxPaidctrl");
      this.removevalidatorcss("labourPaidctrl");
      this.removevalidatorcss("otherPaymentctrl");
      this.removevalidatorcss("updownkmctrl");
      this.removevalidatorcss("totalhourctrl");
      this.removevalidatorcss("extrahourctrl");
      this.removevalidatorcss("detentionctrl");
      this.removevalidatorcss("advancectrl");
      this.removevalidatorcss("balancectrl");
      this.removevalidatorcss("balancePaidctrl");
      
      this.removevalidatorcss("vehicleRatectrl");


      //  var c = this.addNewLR.get('InvoiceNo').value
      //   + ',' + document.getElementById("new1")['value']
      //   + ',' + document.getElementById("new2")['value']
      //   + ',' + document.getElementById("new3")['value'];

      var form = {
        // Add Invoice Details
        "InvoiceNo": this.InvoiceDetails.filteredData,

        // Basic Details
        "manualLR_no": this.addNewLR.get('manualLR_no').value,
        "supervisorId": this.addNewLR.get('supervisorname').value,
        "clientList": this.addNewLR.get('clientList').value,
        "ConsigneeName": this.addNewLR.get('ConsigneeName').value,
        "ConsigneeAddress": this.addNewLR.get('ConsigneeAddress').value,
        "ConsignorName": this.addNewLR.get('ConsignorName').value,
        "ConsignorAddress": this.addNewLR.get('ConsignorAddress').value,
        "VehicleNo": this.addNewLR.get('VehicleNo').value,
        "vehicleType": this.addNewLR.get('vehicleType').value,
        "GSTIN": this.addNewLR.get('GSTIN').value,
        "from": this.addNewLR.get('from').value,
        "to": this.addNewLR.get('to').value,
        "date": this.addNewLR.get('date').value,
        "fixAdhoc": this.addNewLR.get('fixAdhoc').value,
        "quantity": this.addNewLR.get('quantity').value,
        "RoadPermitNo": this.addNewLR.get('RoadPermitNo').value,
        "StateCode": this.addNewLR.get('StateCode').value,
        "DLY": this.addNewLR.get('DLY').value,
        "DeclaredValue": this.addNewLR.get('DeclaredValue').value,
        "transportorName": this.addNewLR.get('transportorName').value,
        "transportorMobile": this.addNewLR.get('transportorMobile').value,
        "driverName": this.addNewLR.get('driverName').value,
        "driverMobile": this.addNewLR.get('driverMobile').value,
        //"tollAttachment": this.TollfilesString,
        "loadingSlipAttchment": this.loadingSlipsString,
        "Frieght": this.addNewLR.get('Frieght').value,
       // "status": this.addNewLR.get('status').value,

        // Information For office use only

        "startmeterReading": this.addNewLR.get('startmeterReading').value,
        "endmeterReading": this.addNewLR.get('endmeterReading').value,
        "tripSDate": this.addNewLR.get('tripSDate').value,
        "tripEDate": this.addNewLR.get('tripEDate').value,
        "INtime": this.addNewLR.get('INtime').value,
        "outtime": this.addNewLR.get('outtime').value,
        "noofTools": this.addNewLR.get('noofTools').value,
        "tooltaxPaid": this.addNewLR.get('tooltaxPaid').value,
        "labourPaid": this.addNewLR.get('labourPaid').value,
        "otherPayment": this.addNewLR.get('otherPayment').value,
        "updownkm": this.addNewLR.get('updownkm').value,
        "totalhour": this.addNewLR.get('totalhour').value,
        "extrahour": this.addNewLR.get('extrahour').value,
        "detention": this.addNewLR.get('detention').value,
        "advance": this.addNewLR.get('advance').value,
        "balance": this.addNewLR.get('balance').value,
        "balancePaid": this.addNewLR.get('balancePaid').value,
        "vehicleRate": this.addNewLR.get('vehicleRate').value,

      }



      //sample-code for calculation

      //  console.log( " Total distance = " + ({ this.addNewLR.get('startmeterReading').value - this.addNewLR.get('endmeterReading').value  });

      //



      const formData = new FormData();
      const files: Array<File> = this.filesToUpload;

      for (let i = 0; i < this.filesToUpload.length; i++) {
        formData.append("uploads[]", this.filesToUpload[i]);
      }
      // for (let i = 0; i < this.Tollfiles.length; i++) {
      //   formData.append("Tollfiles[]", this.Tollfiles[i]);
      // }

      formData.append('myFormData', JSON.stringify(form));
      this.superAdminService.createLR(formData)
        .subscribe((res: { status: any, message: any, data: any, authorization: any, code: any }) => {

          if (res.status == true) {
            // this.spinner.show();
            //alert(res.message)
            this.toastr.success(res.message);
            //this.router.navigate(['/superAdmin/lrmanagement']);
            
            this.router.navigate(['/superAdmin/lrmanagement/detail-lr/' + res.data]);

            //this.addNewLR.reset()
          }
          else {
            // alert(res.message)
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              
              this.router.navigateByUrl('superAuth/login');
              
            }else {
              this.toastr.success(res.message);
            }
            //this.addNewLR.reset()
          }
          this.spinner.hide();
        }, error => {
          console.log(error);
          this.spinner.hide();
        });
    }
    else {
      // Basic Details 
      if (this.addNewLR.get('supervisorname').invalid) {
        this.addvalidatorcss("supervisornamectrl");
      } else {
        this.removevalidatorcss("supervisornamectrl");
      }
      if (this.addNewLR.get('clientList').invalid) {
        this.addvalidatorcss("clientListctrl");
      } else {
        this.removevalidatorcss("clientListctrl");
      }
      if (this.addNewLR.get('ConsigneeAddress').invalid) {
        this.addvalidatorcss("ConsigneeAddressctrl");
      } else {
        this.removevalidatorcss("ConsigneeAddressctrl");
      }
      if (this.addNewLR.get('ConsignorName').invalid) {
        this.addvalidatorcss("ConsignorNamectrl");
      } else {
        this.removevalidatorcss("ConsignorNamectrl");
      }
      if (this.addNewLR.get('ConsigneeName').invalid) {
        this.addvalidatorcss("ConsigneeNamectrl");
      } else {
        this.removevalidatorcss("ConsigneeNamectrl");
      }
      if (this.addNewLR.get('ConsignorAddress').invalid) {
        this.addvalidatorcss("ConsignorAddressctrl");
      } else {
        this.removevalidatorcss("ConsignorAddressctrl");
      }
      if (this.addNewLR.get('VehicleNo').invalid) {
        this.addvalidatorcss("VehicleNoctrl");
      } else {
        this.removevalidatorcss("VehicleNoctrl");
      }
      if (this.addNewLR.get('vehicleType').invalid) {
        this.addvalidatorcss("vehicleTypectrl");
      } else {
        this.removevalidatorcss("vehicleTypectrl");
      }
      if (this.addNewLR.get('from').invalid) {
        this.addvalidatorcss("fromctrl");
      } else {
        this.removevalidatorcss("fromctrl");
      }
      if (this.addNewLR.get('to').invalid) {
        this.addvalidatorcss("toctrl");
      } else {
        this.removevalidatorcss("toctrl");
      }
      if (this.addNewLR.get('date').invalid) {
        this.addvalidatorcss("datectrl");
      } else {
        this.removevalidatorcss("datectrl");
      }
      if (this.addNewLR.get('fixAdhoc').invalid) {
        this.addvalidatorcss("fixAdhocctrl");
      } else {
        this.removevalidatorcss("fixAdhocctrl");
      }
      if (this.addNewLR.get('quantity').invalid) {
        this.addvalidatorcss("quantityctrl");
      } else {
        this.removevalidatorcss("quantityctrl");
      }
      if (this.addNewLR.get('GSTIN').invalid) {
        this.addvalidatorcss("GSTINctrl");
      } else {
        this.removevalidatorcss("GSTINctrl");
      }
      if (this.addNewLR.get('RoadPermitNo').invalid) {
        this.addvalidatorcss("RoadPermitNoctrl");
      } else {
        this.removevalidatorcss("RoadPermitNoctrl");
      }
      if (this.addNewLR.get('StateCode').invalid) {
        this.addvalidatorcss("StateCodectrl");
      } else {
        this.removevalidatorcss("StateCodectrl");
      }
      if (this.addNewLR.get('DLY').invalid) {
        this.addvalidatorcss("DLYctrl");
      } else {
        this.removevalidatorcss("DLYctrl");
      }
      if (this.addNewLR.get('DeclaredValue').invalid) {
        this.addvalidatorcss("DeclaredValuectrl");
      } else {
        this.removevalidatorcss("DeclaredValuectrl");
      }

      if (this.addNewLR.get('transportorName').invalid) {
        this.addvalidatorcss("transportorNamectrl");
      } else {
        this.removevalidatorcss("transportorNamectrl");
      }
      if (this.addNewLR.get('transportorMobile').invalid) {
        this.addvalidatorcss("transportorMobilectrl");
      } else {
        this.removevalidatorcss("transportorMobilectrl");
      }
      if (this.addNewLR.get('driverName').invalid) {
        this.addvalidatorcss("driverNamectrl");
      } else {
        this.removevalidatorcss("driverNamectrl");
      }
      if (this.addNewLR.get('driverMobile').invalid) {
        this.addvalidatorcss("driverMobilectrl");
      } else {
        this.removevalidatorcss("driverMobilectrl");
      }
      // if (this.addNewLR.get('tollAttachment').invalid) {
      //   this.addvalidatorcss("tollAttachmentctrl");
      // } else {
      //   this.removevalidatorcss("tollAttachmentctrl");
      // }
      if (this.addNewLR.get('loadingSlipAttchment').invalid) {
        this.addvalidatorcss("loadingSlipAttchmentctrl");
      } else {
        this.removevalidatorcss("loadingSlipAttchmentctrl");
      }

      if (this.addNewLR.get('Frieght').invalid) {
        this.addvalidatorcss("Frieghtctrl");
      } else {
        this.removevalidatorcss("Frieghtctrl");
      }
      // if (this.addNewLR.get('status').invalid) {
      //   this.addvalidatorcss("statusctrl");
      // } else {
      //   this.removevalidatorcss("statusctrl");
      // }
      // Information For office use only  

      if (this.addNewLR.get('startmeterReading').invalid) {
        this.addvalidatorcss("startmeterReadingctrl");
      } else {
        this.removevalidatorcss("startmeterReadingctrl");
      }
      if (this.addNewLR.get('endmeterReading').invalid) {
        this.addvalidatorcss("endmeterReadingctrl");
      } else {
        this.removevalidatorcss("endmeterReadingctrl");
      }

      if (this.addNewLR.get('tripEDate').invalid) {
        this.addvalidatorcss("tripEDatectrl");
      } else {
        this.removevalidatorcss("tripEDatectrl");
      }
      if (this.addNewLR.get('tripSDate').invalid) {
        this.addvalidatorcss("tripSDatectrl");
      } else {
        this.removevalidatorcss("tripSDatectrl");
      }
      if (this.addNewLR.get('outtime').invalid) {
        this.addvalidatorcss("outtimectrl");
      } else {
        this.removevalidatorcss("outtimectrl");
      }
      if (this.addNewLR.get('INtime').invalid) {
        this.addvalidatorcss("INtimectrl");
      } else {
        this.removevalidatorcss("INtimectrl");
      }
      if (this.addNewLR.get('noofTools').invalid) {
        this.addvalidatorcss("noofToolsctrl");
      } else {
        this.removevalidatorcss("noofToolsctrl");
      }
      if (this.addNewLR.get('tooltaxPaid').invalid) {
        this.addvalidatorcss("tooltaxPaidctrl");
      } else {
        this.removevalidatorcss("tooltaxPaidctrl");
      }
      if (this.addNewLR.get('labourPaid').invalid) {
        this.addvalidatorcss("labourPaidctrl");
      } else {
        this.removevalidatorcss("labourPaidctrl");
      }
      if (this.addNewLR.get('otherPayment').invalid) {
        this.addvalidatorcss("otherPaymentctrl");
      } else {
        this.removevalidatorcss("otherPaymentctrl");
      }
      if (this.addNewLR.get('updownkm').invalid) {
        this.addvalidatorcss("updownkmctrl");
      } else {
        this.removevalidatorcss("updownkmctrl");
      }
      if (this.addNewLR.get('totalhour').invalid) {
        this.addvalidatorcss("totalhourctrl");
      } else {
        this.removevalidatorcss("totalhourctrl");
      }
      if (this.addNewLR.get('extrahour').invalid) {
        this.addvalidatorcss("extrahourctrl");
      } else {
        this.removevalidatorcss("extrahourctrl");
      }
      if (this.addNewLR.get('detention').invalid) {
        this.addvalidatorcss("detentionctrl");
      } else {
        this.removevalidatorcss("detentionctrl");
      }
      if (this.addNewLR.get('advance').invalid) {
        this.addvalidatorcss("advancectrl");
      } else {
        this.removevalidatorcss("advancectrl");
      }
      if (this.addNewLR.get('balance').invalid) {
        this.addvalidatorcss("balancectrl");
      } else {
        this.removevalidatorcss("balancectrl");
      }
      
      if (this.addNewLR.get('balancePaid').invalid) {
        this.addvalidatorcss("balancePaidctrl");
      } else {
        this.removevalidatorcss("balancePaidctrl");
      }
      if (this.addNewLR.get('vehicleRate').invalid) {
        this.addvalidatorcss("vehicleRatectrl");
      } else {
        this.removevalidatorcss("vehicleRatectrl");
      }

    
      this.spinner.hide();
    }
  }
  resetForm() {
    this.addNewLR.reset();
    this.submitLRClicked = false;
    this.removevalidatorcss("clientListctrl");
      this.removevalidatorcss("ConsigneeNamectrl");
      this.removevalidatorcss("ConsigneeAddressctrl");
      this.removevalidatorcss("ConsignorAddressctrl");
      this.removevalidatorcss("ConsignorNamectrl");
      this.removevalidatorcss("VehicleNoctrl");
      this.removevalidatorcss("vehicleTypectrl");
      this.removevalidatorcss("fromctrl");
      this.removevalidatorcss("toctrl");
      this.removevalidatorcss("GSTINctrl");
      this.removevalidatorcss("datectrl");
      this.removevalidatorcss("fixAdhocctrl");
      this.removevalidatorcss("quantityctrl");
      this.removevalidatorcss("balancectrl");
      this.removevalidatorcss("RoadPermitNoctrl");
      this.removevalidatorcss("StateCodectrl");
      this.removevalidatorcss("DLYctrl");
      this.removevalidatorcss("DeclaredValuectrl");
      this.removevalidatorcss("transportorNamectrl");
      this.removevalidatorcss("transportorMobilectrl");
      this.removevalidatorcss("driverNamectrl");
      this.removevalidatorcss("driverMobilectrl");
      //this.removevalidatorcss("tollAttachmentctrl");
      this.removevalidatorcss("loadingSlipAttchmentctrl");
      //this.removevalidatorcss("statusctrl");
      this.removevalidatorcss("Frieghtctrl");


      // Information For office use only
      this.removevalidatorcss("startmeterReadingctrl");
      this.removevalidatorcss("endmeterReadingctrl");
      this.removevalidatorcss("tripSDatectrl");
      this.removevalidatorcss("tripEDatectrl");
      this.removevalidatorcss("outtimectrl");
      this.removevalidatorcss("INtimectrl");
      this.removevalidatorcss("noofToolsctrl");
      this.removevalidatorcss("tooltaxPaidctrl");
      this.removevalidatorcss("labourPaidctrl");
      this.removevalidatorcss("otherPaymentctrl");
      this.removevalidatorcss("updownkmctrl");
      this.removevalidatorcss("totalhourctrl");
      this.removevalidatorcss("extrahourctrl");
      this.removevalidatorcss("detentionctrl");
      this.removevalidatorcss("advancectrl");
      this.removevalidatorcss("balancectrl");
      this.removevalidatorcss("balancePaidctrl");
      
      this.removevalidatorcss("vehicleRatectrl");
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }
  
  onKeyClientName(event: any) {
    if (this.addNewLR.get('clientList').invalid) {
      this.addvalidatorcss("clientListctrl");
    } else {
      this.removevalidatorcss("clientListctrl");
    }
  }
  onKeyConsigneeName(event: any) {
    if (this.addNewLR.get('ConsigneeName').invalid) {
      this.addvalidatorcss("ConsigneeNamectrl");
    } else {
      this.removevalidatorcss("ConsigneeNamectrl");
    }
  }
  onKeyConsigneeAddress(event: any) {
    if (this.addNewLR.get('ConsigneeAddress').invalid) {
      this.addvalidatorcss("ConsigneeAddressctrl");
    } else {
      this.removevalidatorcss("ConsigneeAddressctrl");
    }
  }
  onKeyConsignorName(event: any) {
    if (this.addNewLR.get('ConsignorName').invalid) {
      this.addvalidatorcss("ConsignorNamectrl");
    } else {
      this.removevalidatorcss("ConsignorNamectrl");
    }
  }
  onKeyConsignorAddress(event: any) {
    if (this.addNewLR.get('ConsignorAddress').invalid) {
      this.addvalidatorcss("ConsignorAddressctrl");
    } else {
      this.removevalidatorcss("ConsignorAddressctrl");
    }
  }
  onKeyVehicleNo(event: any) {
    if (this.addNewLR.get('VehicleNo').invalid) {
      this.addvalidatorcss("VehicleNoctrl");
    } else {
      this.removevalidatorcss("VehicleNoctrl");
    }
  }
  onKeyVehicleType(event: any) {
    if (this.addNewLR.get('vehicleType').invalid) {
      this.addvalidatorcss("vehicleTypectrl");
    } else {
      this.removevalidatorcss("vehicleTypectrl");
    }
  }
  onKeyFrom(event: any) {
    if (this.addNewLR.get('from').invalid) {
      this.addvalidatorcss("fromctrl");
    } else {
      this.removevalidatorcss("fromctrl");
    }
  }
  onKeyTo(event: any) {
    if (this.addNewLR.get('to').invalid) {
      this.addvalidatorcss("toctrl");
    } else {
      this.removevalidatorcss("toctrl");
    }
  }
  onKeyGSTIN(event: any) {
    if (this.addNewLR.get('GSTIN').invalid) {
      this.addvalidatorcss("GSTINctrl");
    } else {
      this.removevalidatorcss("GSTINctrl");
    }
  }
  onKeyDate(event: any) {
    if (this.addNewLR.get('date').invalid) {
      this.addvalidatorcss("datectrl");
    } else {
      this.removevalidatorcss("datectrl");
    }
  }
  onKeyRoadPermitNo(event: any) {
    if (this.addNewLR.get('RoadPermitNo').invalid) {
      this.addvalidatorcss("RoadPermitNoctrl");
    } else {
      this.removevalidatorcss("RoadPermitNoctrl");
    }
  }
  onKeyStateCode(event: any) {
    if (this.addNewLR.get('StateCode').invalid) {
      this.addvalidatorcss("StateCodectrl");
    } else {
      this.removevalidatorcss("StateCodectrl");
    }
  }
  onKeyDLY(event: any) {
    if (this.addNewLR.get('DLY').invalid) {
      this.addvalidatorcss("DLYctrl");
    } else {
      this.removevalidatorcss("DLYctrl");
    }
  }
  onKeyDeclaredValue(event: any) {
    if (this.addNewLR.get('DeclaredValue').invalid) {
      this.addvalidatorcss("DeclaredValuectrl");
    } else {
      this.removevalidatorcss("DeclaredValuectrl");
    }
  }
  onKeytransportorName(event: any) {
    if (this.addNewLR.get('transportorName').invalid) {
      this.addvalidatorcss("transportorNamectrl");
    } else {
      this.removevalidatorcss("transportorNamectrl");
    }
  }
  onKeytransportorMobile(event: any) {
    if (this.addNewLR.get('transportorMobile').invalid) {
      this.addvalidatorcss("transportorMobilectrl");
    } else {
      this.removevalidatorcss("transportorMobilectrl");
    }
  }
  onKeyDriverName() {
    if (this.addNewLR.get('driverName').invalid) {
      this.addvalidatorcss("driverNamectrl");
    } else {
      this.removevalidatorcss("driverNamectrl");
    }
  }
  onKeyDriverMobile(event: any) {
    if (this.addNewLR.get('driverMobile').invalid) {
      this.addvalidatorcss("driverMobilectrl");
    } else {
      this.removevalidatorcss("driverMobilectrl");
    }
  }

  onKeyFrieght(event: any) {
    if (this.addNewLR.get('Frieght').invalid) {
      this.addvalidatorcss("Frieghtctrl");
    } else {
      this.removevalidatorcss("Frieghtctrl");
    }
  }

  onKeyloadingSlipAttchment(event: any) {
    if (this.addNewLR.get('loadingSlipAttchment').invalid) {
      this.addvalidatorcss("loadingSlipAttchmentctrl");
    } else {
      this.removevalidatorcss("loadingSlipAttchmentctrl");
    }
  }
  onKeyInvoiceNo(event: any) {
    if (this.addNewLR.get('InvoiceNo').invalid) {
      this.addvalidatorcss("InvoiceNoctrl");
    } else {
      this.removevalidatorcss("InvoiceNoctrl");
    }
  }
  onKeyInvoiceValue(event: any) {
    if (this.addNewLR.get('InvoiceValue').invalid) {
      this.addvalidatorcss("InvoiceValuectrl");
    } else {
      this.removevalidatorcss("InvoiceValuectrl");
    }
  }
  onKeyNoofArticle(event: any) {
    if (this.addNewLR.get('NoofArticle').invalid) {
      this.addvalidatorcss("NoofArticlectrl");
    } else {
      this.removevalidatorcss("NoofArticlectrl");
    }
  }
  onKeyFixAdhoc(event: any) {
    if (this.addNewLR.get('fixAdhoc').invalid) {
      this.addvalidatorcss("fixAdhocctrl");
    } else {
      this.removevalidatorcss("fixAdhocctrl");
    }
  }
  onKeyQuantity(event: any) {
    if (this.addNewLR.get('quantity').invalid) {
      this.addvalidatorcss("quantityctrl");
    } else {
      this.removevalidatorcss("quantityctrl");
    }
  }
  onKeyWeight(event: any) {
    if (this.addNewLR.get('Weight').invalid) {
      this.addvalidatorcss("Weightctrl");
    } else {
      this.removevalidatorcss("Weightctrl");
    }
  }
  onKeyTransportorName(event: any) {
    if (this.addNewLR.get('transportorName').invalid) {
      this.addvalidatorcss("transportorNamectrl");
    } else {
      this.removevalidatorcss("transportorNamectrl");
    }
  }
  onKeyTransportorMobile(event: any) {
    if (this.addNewLR.get('transportorMobile').invalid) {
      this.addvalidatorcss("transportorMobilectrl");
    } else {
      this.removevalidatorcss("transportorMobilectrl");
    }
  }
  onKeyCustomerName(event: any) {
    if (this.addNewLR.get('customerName').invalid) {
      this.addvalidatorcss("customerNamectrl");
    } else {
      this.removevalidatorcss("customerNamectrl");
    }
  }
  onKeyStartMeterReading(event: any) {
    if (this.addNewLR.get('startmeterReading').invalid) {
      this.addvalidatorcss("startmeterReadingctrl");
    } else {
      this.removevalidatorcss("startmeterReadingctrl");
    }
  }
  onKeyEndMeterReading(event: any) {
    if (this.addNewLR.get('endmeterReading').invalid) {
      this.addvalidatorcss("endmeterReadingctrl");
    } else {
      this.removevalidatorcss("endmeterReadingctrl");
    }
  }


  backTo() {
    window.history.back()
  }
  get gstin(){
    return this.addNewLR.get('GSTIN')
  }
  get InvoiceValue(){
    return this.addNewLR.get('InvoiceValue')
  }
  
  get Weight(){
    return this.addNewLR.get('Weight')
  }

  get NoofArticle(){
    return this.addNewLR.get('NoofArticle')
  }
  preview(index){
    let file = this.filesToUpload[index];
    if(file){
      if(['image/jpeg','image/png','image/svg','image/svg+xml'].includes(file.type)){
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width:'80%',
          height:'90vh',
          data:{file:file}
      });
      }
      else{
        let url =URL.createObjectURL(file);
        this.podParticularURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(()=>{
          this.showParticularPOD.nativeElement.click();
        })
      }
    }
    else{
      this.toastr.error("Please upload attachment")
    }
  }
  previewLR(){
    if(this.loadingSlips[0]){
      if(['image/jpeg','image/png','image/svg','image/svg+xml'].includes(this.loadingSlips[0].type)){
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
            width:'80%',
            height:'90vh',
            data:{file:this.loadingSlips[0]}
        });
      }
      else{
        let url =URL.createObjectURL(this.loadingSlips[0]);
        this.docURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(()=>{
          this.showDoc.nativeElement.click();
        })
      }
    }
    else{
      this.toastr.error("Please upload attachment")
    }
  }
  previewPOD(){
    let file = this.filesToUpload.slice(-1)[0];
    if(file){
      if(['image/jpeg','image/png','image/svg','image/svg+xml'].includes(file.type)){
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width:'80%',
          height:'90vh',
          data:{file:file}
      });
      }
      else{
        let url =URL.createObjectURL(file);
        this.podURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(()=>{
          this.showPOD.nativeElement.click();
        })
      }
    }
    else{
      this.toastr.error('No file selected for Preview')
    }
  }
}
